import styles from './Actions.module.css';

import printerIconBlack from '../../assets/printer-icon-black.svg';
import whatsappIconBlue from '../../assets/whatsapp-icon-blue.svg';

import { useFiltrosContext } from '../../contexts/FiltrosContext';
import InputMonthYear from '../inputMonthYear/InputMonthYear';
import SecondaryButton from '../secondaryButton/SecondaryButton';
import { useDreContext } from '../../contexts/DreContext';
import React from 'react';

const Actions = ({
    hasPrintButton = false,
    onPrint = () => { }
}) => {
    const {
        getFiltros,
        setFiltroProp
    } = useFiltrosContext();

    const { mes_ref } = getFiltros();
    const { getData } = useDreContext();

    React.useEffect(() => {
        getData();
    }, [mes_ref]);

    return (
        <section className={styles.actions}>
            <div className={styles.left}>
                <label>Selecione o mês: </label>
                <InputMonthYear
                    value={mes_ref}
                    onChange={newDate => setFiltroProp('mes_ref', newDate)}
                />

                {hasPrintButton && <button className={styles.printButton} onClick={onPrint}>
                    Imprimir
                    <img src={printerIconBlack} alt="" />
                </button>}
            </div>
            <SecondaryButton
                label='Ajuda'
                hasIconRight={true}
                icon={whatsappIconBlue}
            />
        </section>
    );
}

export default Actions;