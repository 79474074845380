import React from "react";

import BarChart from "./BarChartComponent";

interface SingleStackedBarChartParams {
  data: {
    yLeft: Array<number>;
    yRight: Array<number>;
    x: Array<number>;
  };
  legend: {
    yLeft: string;
    yRight: string;
  };
  dataLabelFormatter?(label: string): string;
  isHorizontal?: boolean;
  height?: string;
  width?: string;
  showLabels?: boolean;
  showBorder?: boolean;
  isPrimary?: boolean;
  hasHoverAnimation?: boolean;
}

const SingleStackedBarChart: React.FC<SingleStackedBarChartParams> = ({
  data = {
    yLeft: [],
    yRight: [],
    x: [],
  },
  legend = {
    yLeft: "",
    yRight: "",
  },
  dataLabelFormatter = (label) => {},
  isHorizontal = false,
  height = "100%",
  width = "auto",
  showLabels = true,
  showBorder = true,
  isPrimary = true,
  hasHoverAnimation = true,
}) => {
  // TODO: fix the useEffect
  // good luck :P

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    indexAxis: isHorizontal ? "y" : "x",
    plugins: {
      datalabels: {
        display: showLabels,
        align: "center",
        anchor: "end",
        font: {
          size: 12,
        },
        formatter: function (value: string) {
          return dataLabelFormatter(value);
        },
      },
      legend: {
        display: showLabels,
        align: "end",
        position: "top",
      },
      tooltip: {
        enabled: hasHoverAnimation,
        yAlign: "bottom",
      },
    },
    scales: {
      x: {
        display: false,
        stacked: true,
        grid: { display: false, tickLength: 0 },
        border: { display: showBorder },
        ticks: {
          callback: (value: string) => dataLabelFormatter(value),
        },
      },
      y: {
        stacked: true,
        grid: { display: false, tickLength: 0 },
        border: { display: showBorder },
        ticks: { display: false },
      },
    },
  };

  const chartData = {
    labels: data.x,
    datasets: [
      {
        label: legend.yLeft,
        data: data.yLeft,
        backgroundColor: "#0047BA",
        // borderRadius: Number.MAX_SAFE_INTEGER,
        borderSkipped: false,
        datalabels: {
          labels: {
            title: {
              color: "#fff",
            },
          },
        },
      },
      {
        label: legend.yRight,
        data: data.yRight,
        backgroundColor: isPrimary ? "#F5E100" : "#cfcfcf",
        // borderRadius: Number.MAX_SAFE_INTEGER,
        datalabels: {
          labels: {
            title: {
              color: "#2a2a2a",
            },
          },
        },
      },
    ],
  };

  return (
    <BarChart
      data={chartData}
      options={options}
      height={height}
      width={width}
    />
  );
};

export default SingleStackedBarChart;
