import React, { createContext } from "react";
import { DashboardData } from "../types/Dashboard";

interface DashboardContextParams {
    getData(): Promise<void>;
    dashboardData: DashboardData
}

const DashboardContext = createContext<DashboardContextParams>({
    getData: async () => { },
    dashboardData: null
});

export default DashboardContext;
export const useDashboardContext = () => React.useContext(DashboardContext);
