import React from 'react';

import styles from './PrintDreDividendos.module.css';

import Card from '../../../components/card/Card';

import logoPlinFullIconWhite from '../../../assets/logo-plin-full-icon-white.svg'
import coinDollarIconBlack from '../../../assets/coin-dollar-icon-black.svg'
import toolsIconBlack from '../../../assets/tools-icon-black.svg'
import bankIconBlack from '../../../assets/bank-icon-black.svg'
import investmentArrowIconWhite from '../../../assets/investment-arrow-icon-white.svg'
import handCoinIconBlack from '../../../assets/hand-coin-icon-black.svg'

import CardInfoColored from '../../../components/cardInfoColored/CardInfoColored';
import SpanStatus from '../../../components/spanStatus/SpanStatus';
import InfoButton from '../../../components/infoButton/InfoButton';
import CardInfo from '../cardInfo/CardInfo';
import RecebimentosPopup from '../recebimentosDividendosPopup/RecebimentosPopup';
import { useDreContext } from '../../../contexts/DreContext';

const PrintDreDividendos = () => {
    const { dreData } = useDreContext();


    const previsaoDePagamentoStatus = {
        bgColor: 'var(--verde-positivo)',
        fontColor: 'var(--branco)',
        isFill: true,
        label: 'Pago'
    }

    React.useEffect(() => {
        // TODO: when got the context working, two possibilities:
        // - do the GET on useEffect and on `then` do window.print()
        // - call this page passing all data as params (try POST)
        setTimeout(() => window.print(), 500);

        window.onafterprint = () => {
            window.close()
        }
    }, [window.print]);

    return (
        <main>
            <div className={styles.hidder}></div>
            <header className={styles.header}><img src={logoPlinFullIconWhite} /></header>

            <section className={`${styles.cards} ${styles.cardsCri}`}>
                <div className={styles.column}>
                    <Card
                        title='Recebimentos'
                        icon={coinDollarIconBlack}
                        classNameContent={`${styles.cardContent} ${styles.recebimentosCard}`}
                        button={<InfoButton />}
                    >
                        <div className={styles.values}>
                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Geração"
                                    value="681,03 MWh"
                                />
                                <CardInfoColored
                                    color="var(--vermelho-erro)"
                                    label="Consumida"
                                    value="1,32 MWh"
                                />
                                <CardInfoColored
                                    color="var(--vermelho-erro)"
                                    label="Energia retida"
                                    value="1,00 MWh"
                                />
                            </div>
                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Injetada"
                                    value="678,71 MWh"
                                />
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Saldo meses anteriores"
                                    value="564,46 MWh"
                                />
                            </div>
                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--azul-1)"
                                    label="Compensada"
                                    value="1.143,17 MWh"
                                />
                                <CardInfoColored
                                    color="var(--amarelo)"
                                    label="Saldo"
                                    value="100,00 MWh"
                                />
                            </div>
                        </div>
                        <div className={styles.total}>
                            <label>Faturamento Bruto</label>
                            <span>R$ 612.736,44</span>
                        </div>
                    </Card>

                    <Card
                        title='Faturamento'
                        icon={handCoinIconBlack}
                        classNameContent={`${styles.cardContent} ${styles.faturamentoCard}`}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="Valor pendente"
                                value="R$ 114.800,00"
                            />
                            <CardInfo
                                label="Valor recebido"
                                value="R$ 497.936,44"
                            />
                            <CardInfo
                                label="Recebidos com atraso"
                                value="R$ 11.287,02"
                            />
                            <CardInfo
                                label="Juros e Multas"
                                value="R$ 3.512,98"
                            />
                        </div>

                        <div className={styles.total}>
                            <label>Total</label>
                            <span>R$ 512.736,44</span>
                        </div>
                    </Card>

                    <Card
                        title='Receita Tributável da Usina - SPE'
                        icon={coinDollarIconBlack}
                        classNameContent={styles.cardContent}
                    >
                        <div className={styles.values}>
                            <div className={styles.total}>
                                <label>Valor</label>
                                <span>R$ 374.820,26</span>
                            </div>
                        </div>
                    </Card>

                    <Card
                        title='Tributos'
                        icon={bankIconBlack}
                        classNameContent={styles.cardContent}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="CSLL"
                                value="-R$ 10.794,84"
                            />
                            <CardInfo
                                label="IRPJ"
                                value="-R$ 17.991,40"
                            />
                            <CardInfo
                                label="IRPJ Adicional"
                                value="-R$ 9.994,27"
                            />
                        </div>
                        <div className={styles.total}>
                            <label>Total</label>
                            <span>-R$ 38.780,51</span>
                        </div>
                    </Card>

                    <Card
                        icon={investmentArrowIconWhite}
                        title='Rentabilidade Mensal do Investidor'
                        button={<span className={styles.rentabilitySpan}>1.00%</span>}
                        classNameCard={styles.cardRentabilityCri}
                    ></Card>
                </div>

                <div className={styles.column}>
                    <Card hasHeader={false} classNameCard={styles.popupCard}>
                        <RecebimentosPopup isPrint={true} data={dreData} />
                    </Card>

                    <Card
                        title='Despesas do Consórcio'
                        icon={handCoinIconBlack}
                        classNameContent={styles.cardContent}
                    >
                        <CardInfo
                            label="Fatura da Copel"
                            value="-R$ 94.474,42"
                        />
                        <CardInfo
                            label="Gestão e Comercialização (PLIN)"
                            value="-R$ 43.441,12"
                        />
                        <div className={styles.values}>
                            <div className={styles.total}>
                                <label>Valor</label>
                                <span>-R$ 137.915,54</span>
                            </div>
                        </div>
                    </Card>

                    <Card
                        title='Deduções e Despesas'
                        icon={toolsIconBlack}
                        classNameContent={`${styles.cardContent} ${styles.deducoesDespesas}`}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="PIS/COFINS"
                                value="-R$ 13.680,96"
                                isBold={false}
                            />
                            <CardInfo
                                label="Despesas"
                                value="-R$ 50.999,78"
                                isBold={false}
                                button={
                                    <InfoButton
                                        paragraph={<div className={`${styles.values} ${styles.deducoesDespesasPopup}`}>
                                            <CardInfo
                                                label="Despesas com O&M"
                                                value="R$ 25.000,00"
                                                isBold={false}
                                            />
                                            <CardInfo
                                                label="Arrendamento do Terreno"
                                                value="R$ 11.625,87"
                                                isBold={false}
                                            />
                                            <CardInfo
                                                label="Seguro da Usina"
                                                value="R$ 11.581,55"
                                                isBold={false}
                                            />
                                            <CardInfo
                                                label="Despesas Gerais e Extras"
                                                value="R$ 2.792,36"
                                                isBold={false}
                                            />
                                        </div>}
                                    />
                                }
                            />
                        </div>
                        <div className={styles.total}>
                            <label>EBITDA</label>
                            <span>R$ 310.140,16</span>
                        </div>
                    </Card>
                    <Card
                        icon={coinDollarIconBlack}
                        title='Resultado Líquido da Usina'
                        classNameContent={styles.cardContent}
                    >
                        <div className={styles.values}>
                            <div className={styles.values}>
                                <CardInfo
                                    label="Total de dividendos"
                                    value="R$ 271.359,65"
                                    isBold={false}
                                />
                            </div>
                            <div className={styles.total}>
                                <label>Dividendos proporcionais (80%)</label>
                                <span>R$ 217.087,72</span>
                            </div>
                            <div className={styles.previsaoPagamento}>
                                <label>Previsão de pagamento: 10/02</label>
                                <SpanStatus
                                    {...previsaoDePagamentoStatus}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </section>
        </main >
    );
}

export default PrintDreDividendos;