import React from 'react';

import styles from './Documentos.module.css';

import {ReactComponent as MagnifyingGlassIconBlue} from '../../assets/magnifying-glass-icon-blue.svg';
import {ReactComponent as PrinterIconBlue} from '../../assets/printer-icon-blue.svg';
import {ReactComponent as DownloadIconBlue} from '../../assets/download-icon-blue.svg';

import Actions from '../../components/actions/Actions';
import Card from '../../components/card/Card';

const Documentos = () => {
    const naoImplementado = () => alert("Funcionalidade não implementada");

    return (
        <main>
            <Actions />

            <section className={styles.items}>
                <Card
                    hasHeader={false}
                    classNameContent={styles.cardItem}
                >
                    <label>Fatura da usina janeiro/2023</label>
                    <div>
                        <button onClick={naoImplementado}>
                            <MagnifyingGlassIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <PrinterIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <DownloadIconBlue />
                        </button>
                    </div>
                </Card>
                <Card
                    hasHeader={false}
                    classNameContent={styles.cardItem}
                >
                    <label>Demonstrativo janeiro/2023</label>
                    <div>
                        <button onClick={naoImplementado}>
                            <MagnifyingGlassIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <PrinterIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <DownloadIconBlue />
                        </button>
                    </div>
                </Card>
                <Card
                    hasHeader={false}
                    classNameContent={styles.cardItem}
                >
                    <label>Comprovante de pagamento dividendos janeiro/2023</label>
                    <div>
                        <button onClick={naoImplementado}>
                            <MagnifyingGlassIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <PrinterIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <DownloadIconBlue />
                        </button>
                    </div>
                </Card>

                <h3 className={styles.contatos}>Contratos</h3>

                <Card
                    hasHeader={false}
                    classNameContent={styles.cardItem}
                >
                    <label>Contrato de locação PLIN</label>
                    <div>
                        <button onClick={naoImplementado}>
                            <MagnifyingGlassIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <PrinterIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <DownloadIconBlue />
                        </button>
                    </div>
                </Card><Card
                    hasHeader={false}
                    classNameContent={styles.cardItem}
                >
                    <label>Contrato de comodato PLIN</label>
                    <div>
                        <button onClick={naoImplementado}>
                            <MagnifyingGlassIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <PrinterIconBlue />
                        </button>
                        <button onClick={naoImplementado}>
                            <DownloadIconBlue />
                        </button>
                    </div>
                </Card>
            </section>
        </main>
    );
}

export default Documentos;