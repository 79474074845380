import InputSearchOption from "../../types/InputSearchOption";
import styles from "./InputSelect.module.css";

import Select from "react-select";

interface InputSelectParams {
    value: InputSearchOption,
    options: any,
    placeholder?: string,
    onChange(newValue: any): void,
    isSearchable?: boolean,
    isDisabled?: boolean,
    hasIcon?: boolean,
    className?: string
}

const InputSelect: React.FC<InputSelectParams> = ({
    value = null,
    options = [],
    placeholder = "",
    onChange,
    isSearchable = true,
    isDisabled = false,
    hasIcon = true,
    className = ""
}) => {
    return (
        <Select
            options={options}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            // menuIsOpen={true}
            className={`${className} ${styles.select} ${!hasIcon && styles.noIcon} ${value && styles.selected}`}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    border: ".1rem solid #cfcfcf",
                    color: "#575757",
                    outline: "none",
                    borderRadius: "8px",
                    // ":hover": ({
                    //     border: "none",
                    //     borderColor: "transparent",
                    //     borderWidth: 0
                    // })
                }),
                indicatorSeparator: () => ({
                    display: "none",
                }),
                indicatorsContainer: (baseStyles) => ({
                    ...baseStyles,
                    color: "#03053D",
                }),
                menu: (baseStyles) => ({
                    ...baseStyles,
                    zIndex: 99999,
                })
            }}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
        />
    );
};

export default InputSelect;
