import React from 'react';

import TableComponent from '../../components/tableComponent/TableComponent';

import { makeId } from '../../utils/utils';
import { usePaginationContext } from '../../contexts/PaginationContext';

const TableFluxoDeCaixa = () => {

    const renderHeaders = () => {
        const headerData = ["Ano", "Fluxo de caixa", "Saldo"];
        return headerData.map((header, i) => <th key={i}>{header}</th>);
    }

    const renderRows = () => {
        const rowsData: Array<Array<number>> = [
            [0, -18000, -18000],
            [1, 5425, -12575],
            [2, 5994, -6581],
            [3, 6692, -489],
            [4, 4458, 3969]
        ];
        return rowsData.map((row, i) => {
            return (
                <tr key={i}>
                    <td key={i + makeId()}>{row[0]}</td>
                    <td key={i + makeId()}>{row[1].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                    <td
                        key={i + makeId()}
                        style={{
                            color: row[2] < 0 ? 'var(--vermelho-erro' : 'var(--preto)'
                        }}
                    >{row[2].toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                </tr>
            )
        });
    }

    const { getPagination, setPaginationProp } = usePaginationContext();

    const isLoading = false;

    return (
        <TableComponent
            renderHeaders={renderHeaders}
            renderRows={renderRows}
            isLoading={isLoading}
            getPaginationData={getPagination}
            setPaginationData={setPaginationProp}
        />
    );
}

export default TableFluxoDeCaixa;