import React from 'react';

import styles from './Header.module.css';

import flashCircleIconWhite from '../../assets/flash-circle-icon-white.svg';
import flashPinIconWhite from '../../assets/flash-pin-icon-white.svg';
import lightbulbFlashIconWhite from '../../assets/lightbulb-flash-icon-white.svg';
import logoutIconWhite from "../../assets/logout-icon-white.svg";
import pizzaChartIconWhite from '../../assets/pizza-chart-icon-white.svg';
import plugLineIconWhite from '../../assets/plug-line-icon-white.svg';
import usinaSolarIconWhite from "../../assets/usina-solar-icon-white.svg";

import { useAuthContext } from '../../contexts/AuthContext';
import InputSearchOption from '../../types/InputSearchOption';
import InputSelect from '../inputSelect/InputSelect';
import SpanStatus from '../spanStatus/SpanStatus';
import { useUsinasContext } from '../../contexts/UsinasContext';

interface HeaderParams {
    isOpen: boolean
}

const Header: React.FC<HeaderParams> = ({
    isOpen = false
}) => {
    const [usinaSelected, setUsinaSelected] = React.useState<InputSearchOption>(null);

    const {
        usinasForSelect,
        getUsinasForSelect,
        getUsinaById
    } = useUsinasContext();

    React.useLayoutEffect(() => {
        getUsinasForSelect();
    }, []);

    React.useEffect(() => {
        if (!usinaSelected) return;
        getUsinaById(usinaSelected.value as string);
    }, [usinaSelected]);

    React.useEffect(() => {
        setUsinaSelected(usinasForSelect[0]);
    }, [usinasForSelect, usinasForSelect[0], usinasForSelect[0]?.label]);

    const { doLogout } = useAuthContext();

    const handleLogout = () => {
        doLogout();
        window.location.href = '/login';
    }

    return (
        <section className={`${styles.header} ${isOpen ? styles.isOpen : ''}`}>
            <div className={styles.headerItem}>
                <img src={lightbulbFlashIconWhite} alt="" />
                <h2>José Rafael Hermoso</h2>
            </div>

            <div className={styles.right}>
                <div className={styles.headerItem}>
                    <img src={usinaSolarIconWhite} alt="Local da usina" />

                    <strong>Usina: </strong>
                    {usinasForSelect.length > 1 ?
                        <InputSelect
                            className={styles.usinaSelect}
                            options={usinasForSelect}
                            onChange={setUsinaSelected}
                            value={usinaSelected}
                            hasIcon={false}
                        />
                        : <SpanStatus
                            bgColor='var(--branco)'
                            label={usinaSelected?.label}
                        />
                    }
                </div>

                <div className={styles.headerItem}>
                    <img src={pizzaChartIconWhite} alt="Número da U.C." />
                    <span><strong>Percentual: </strong> 100%</span>
                </div>

                <div className={styles.headerItem}>
                    <img src={plugLineIconWhite} alt="Número da U.C." />
                    <span><strong>UC:</strong> 123456789</span>
                </div>

                <div className={styles.headerItem}>
                    <img src={flashCircleIconWhite} alt="" />
                    <span><strong>Cap. instalada: </strong>75 kW</span>
                </div>
                <div className={styles.headerItem}>
                    <img src={flashPinIconWhite} alt="Endereço da U.C" />
                    <span><strong>Localização:</strong> Maringá, PR</span>
                </div>
                <div className={`${styles.headerItem} ${styles.logoutBtn}`} onClick={handleLogout}>
                    <span>Sair</span>
                    <img src={logoutIconWhite} alt="sair" />
                </div>
            </div>
        </section>
    );
}

export default Header;