import { toast } from "react-toastify";

export const toastOptionsDesktop = {
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: true,
    pauseOnFocusLoss: false,
    autoClose: 1000,
    style: {
        minWidth: "150px",
        minHeight: "120px",
        top: "30vh",
        borderRadius: "5px",
        zIndex: 9000,
    },
};

export const navigateToExternal = (url: string) => window.open(url, '_blank');

export const makeId = () => {
    const numericId = Math.random() * 10 ** 6;
    const intId = Math.floor(numericId);
    const stringId = `${intId}`;
    return stringId;
}
