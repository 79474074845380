import React from "react";
import { useFiltrosContext } from "../contexts/FiltrosContext";
import DashboardGateway from "../gateways/interfaces/DashboardGateway";
import { DashboardData } from "../types/Dashboard";
import DashboardContext from "../contexts/DashboardContext";

interface DashboardProviderParams {
    children: React.ReactNode;
    dashboardGateway: DashboardGateway;
}

export const DashboardProvider: React.FC<DashboardProviderParams> = ({
    children,
    dashboardGateway
}) => {
    const { getFiltros } = useFiltrosContext();
    const [dashboardData, setDashboardData] = React.useState<DashboardData>(null);

    const getData = async () => {
        const filtros = getFiltros();
        const data = await dashboardGateway.getLastSixMonthsData(filtros);
        setDashboardData(data);
    }

    return (
        <DashboardContext.Provider
            value={{
                getData,
                dashboardData
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};
