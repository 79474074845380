import React from 'react';

import styles from './MovimentacoesPopup.module.css';

import PopupIndicator from '../../../components/popupIndicator/PopupIndicator';
import { formatToKwh } from '../../../utils/formatters';
import Dre from '../../../types/Dre';

interface MovimentacoesPopupParams {
    dreData: Dre;
}

const MovimentacoesPopup: React.FC<MovimentacoesPopupParams> = ({
    dreData
}) => {
    return (
        <div className={styles.movimentacoesPopupContent}>
            <div>
                <PopupIndicator className={styles.firstIndicator} />
                <label>{`Da geração é descontado a energia\nconsumida e a retida, resultando\nno valor injetado de `}<strong>13.407 kWh</strong></label>
            </div>
            <div>
                <PopupIndicator className={styles.secondIndicator} />
                <label>{`Porém apenas `}<strong>{formatToKwh(dreData.energia_compensada)}</strong>{` foi\ncompensado esse mês, restando\n`}<strong>{formatToKwh(dreData.saldo)}</strong>{` para os próximos meses.`}</label>
            </div>
        </div>
    );
}

export default MovimentacoesPopup;