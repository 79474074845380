import React, { createContext } from "react";
import FaturaBeneficiaria from "../types/Beneficiarias";
import BeneficiariasGateway from "../gateways/interfaces/BeneficiariasGateway";
import { useFiltrosContext } from "./FiltrosContext";

const emptyFaturaBeneficiarias: FaturaBeneficiaria = {
    id: "",
    data_vencimento: new Date(),
    data_pagamento: null,
    valor_compensado: 0,
    kwh_compensado: 0,
    beneficiaria_id: "",
    data_ref: new Date(),
    uc: "",
    file_key: "",
    kwh_acumulado_fp: 0,
    kwh_acumulado_p: 0,
    kwh_billed: 0,
    kwh_consumido: 0,
    micro_mini_geracao: false,
    valor_energia: 0,
    energia_injetada_kwh: 0,
    energia_injetada_reais: 0,
    saldo_kwh: 0,
    saldo_reais: 0
}

interface BeneficiariasContextParams {
    isLoading: boolean,
    cobrancasBeneficiarias: Array<FaturaBeneficiaria>
    getCobrancasBeneficiarias(): Promise<void>,
    beneficiariasPagas: number,
    getCobrancasPagas(): Promise<void>
}

const BeneficiariasContext = createContext<BeneficiariasContextParams>({
    isLoading: true,
    cobrancasBeneficiarias: [],
    getCobrancasBeneficiarias: async () => { },
    beneficiariasPagas: 0,
    getCobrancasPagas: async () => { }
});

export const useBeneficiariasContext = () => React.useContext(BeneficiariasContext);

interface BeneficiariasProviderParams {
    children: React.ReactNode;
    beneficiariasGateway: BeneficiariasGateway;
}

export const BeneficiariasProvider: React.FC<BeneficiariasProviderParams> = ({
    children,
    beneficiariasGateway
}) => {
    const { getFiltros } = useFiltrosContext();

    const [isLoading, setIsLoading] = React.useState(true);
    const [cobrancasBeneficiarias, setCobrancasBeneficiarias] = React.useState<Array<FaturaBeneficiaria>>([]);
    const [beneficiariasPagas, setBeneficiariasPagas] = React.useState(0)

    const getCobrancasBeneficiarias = async () => {
        setIsLoading(true);
        const filtros = getFiltros();
        const cobrancas = await beneficiariasGateway.getCobrancasBeneficiarias(filtros);
        setCobrancasBeneficiarias(cobrancas);
        setIsLoading(false);
    }

    const getCobrancasPagas = async () => {
        setIsLoading(true);
        let filtros = getFiltros();

        if (cobrancasBeneficiarias.length == 0) await beneficiariasGateway.getCobrancasBeneficiarias(filtros);

        filtros = {
            ...filtros,
            bill_status: {
                value: true,
                label: 'Sim'
            }
        };
        console.log('isLoading > ', isLoading);

        const cobrancasPagas = await beneficiariasGateway.getCobrancasBeneficiarias(filtros);

        setBeneficiariasPagas(cobrancasPagas.length);
        setIsLoading(false);
    }

    return (
        <BeneficiariasContext.Provider
            value={{
                isLoading,
                cobrancasBeneficiarias,
                getCobrancasBeneficiarias,
                beneficiariasPagas,
                getCobrancasPagas
            }}
        >
            {children}
        </BeneficiariasContext.Provider>
    );
};
export default BeneficiariasContext;