import Usina from "./Usina";

export enum Bandeira {
    VERDE = 'VERDE',
    AMARELA = 'AMARELA',
    VERMELHA_1 = 'VERMELHA_1',
    VERMELHA_2 = 'VERMELHA_2',
}

export enum StatusDRE {
    PRONTA = 'PRONTA',
    TRAVADA = 'TRAVADA',
    ERRO = 'ERRO',
}

export default interface Dre {
    id: string;
    usina_id: string;
    usina: Usina;
    mes_ref: Date;
    bandeira: Bandeira;
    status_dre: StatusDRE;
    geracao: number;
    consumo: number;
    energia_retida: number;
    energia_injetada: number;
    saldo: number;
    saldo_meses_anteriores: number;
    energia_compensada: number;
    receita_usina: number;
    valor_pendente: number;
    valor_recebido: number;
    recebido_atraso: number;
    juros_multa: number;
    faturamento_bruto: number;
    fatura_copel: number;
    gestao_plin: number;
    total_copel_gestao_plin: number;
    receita_tributavel_usina: number;
    pis_cofins: number;
    despesas_financeiras: number;
    outras_despesas: number;
    despesas_oem: number,
    arrendamento_terreno: number,
    seguro_usina: number,
    despesas: number;
    ebtida: number;
    csll: number;
    irpj: number;
    irpj_adicional: number;
    total_tributos: number;
    total_dividendos: number;
    dividendos_proporcao: number;
    previsao_pagamento: Date;
    pago: boolean;
    rentabilidade: number;
}
