import React, { createContext } from "react";

import UsinasGateway from "../gateways/interfaces/UsinasGateway";
import InputSearchOption from "../types/InputSearchOption";
import Usina from "../types/Usina";
import { useFiltrosContext } from "./FiltrosContext";

interface UsinasContextType {
    isLoading: boolean,
    getUsinas(): Promise<void>;
    getUsinaById(id: string): Promise<void>;
    usinas: Array<Usina>;
    usinaEspecifica: Usina | null;
    getUsinasForSelect: () => void;
    usinasForSelect: Array<InputSearchOption>;
}

const UsinasContext = createContext<UsinasContextType>({
    isLoading: true,
    getUsinas: async () => { },
    getUsinaById: async (id) => { },
    usinas: [],
    usinaEspecifica: null,
    getUsinasForSelect: () => { },
    usinasForSelect: [],
});

export const useUsinasContext = () => React.useContext(UsinasContext);

interface UsinasProviderParams {
    children: React.ReactNode;
    usinasGateway: UsinasGateway;
}

export const UsinasProvider: React.FC<UsinasProviderParams> = ({
    children,
    usinasGateway,
}) => {
    const { getFiltros } = useFiltrosContext();

    const [isLoading, setIsLoading] = React.useState(true);
    const [usinas, setUsinas] = React.useState<Array<Usina>>([]);
    const [usinaEspecifica, setUsinaEspecifica] = React.useState<Usina | null>(null);
    const [usinasForSelect, setUsinasForSelect] = React.useState<Array<InputSearchOption>>([]);

    const getUsinas = async () => {
        setIsLoading(true);
        const filtros = getFiltros();
        const usinas = await usinasGateway.getUsinas(filtros);
        setUsinas(usinas);
        setIsLoading(false);
    }

    const getUsinaById = async (id: string) => {
        setIsLoading(true);
        const usina = await usinasGateway.getUsinaById(id);
        setUsinaEspecifica(usina);
        setIsLoading(false);
    }

    const getUsinasForSelect = async () => {
        setIsLoading(true);
        const usinas = await usinasGateway.getUsinasForSelect();
        setUsinasForSelect(usinas);
        setIsLoading(false);
    }

    return (
        <UsinasContext.Provider
            value={{
                isLoading,
                getUsinas,
                getUsinaById,
                usinas,
                usinaEspecifica,
                getUsinasForSelect,
                usinasForSelect,
            }}
        >
            {children}
        </UsinasContext.Provider>
    );
};
