import React from 'react';

import SingleStackedBarChart from '../../components/barChart/SingleStackedBarChart';

const ChartPaybackProjetado = () => {
    const fakeData = {
        yLeft: [823],
        yRight: [177],
        x: [0]
    }

    // TODO: fix the x axis, it is the datalabel that show, in the
    // chart, use th dataLAbelFormater :p
    // Good Luck!

    const fakeLegend = {
        yLeft: 'Beneficiárias que pagaram',
        yRight: 'Beneficiárias cadastradas'
    }

    let chartWidth = '350px';
    React.useEffect(() => {
        const element = document.getElementById("cardBeneficiarias");
        if (!element) return;
        chartWidth = `${element.clientWidth - 80}px`;

        // TODO: fix the chart width, it gets the value but because of
        // useEffect it doesn't apply to the chart
        // Good Luck :P
        // console.log('chartWidth > ', chartWidth);
    }, []);

    return (
        <SingleStackedBarChart
            data={fakeData}
            legend={fakeLegend}
            isHorizontal={true}
            height='8px'
            // width={chartWidth}
            isPrimary={false}
            showLabels={false}
            showBorder={false}
        />
    );
}

export default ChartPaybackProjetado;