import React from "react";

import styles from "./TableComponent.module.css";

import leftArrowIconBlack from "../../assets/left-arrow-icon-black.svg";
import rightArrowIconBlack from "../../assets/right-arrow-icon-black.svg";
import doubleArrowLeft from "../../assets/double-arrow-left.svg";
import doubleArrowRight from "../../assets/double-arrow-right.svg";
import Pagination, { PaginationKeys, PaginationValues } from "../../types/Pagination";
import { initialPagination } from "../../reducers/PaginationReducer";



interface TableComponentParams {
    className?: string,
    renderHeaders(): Array<React.ReactNode>,
    renderRows(): Array<React.ReactElement>,
    setPaginationData?(key: PaginationKeys, value: PaginationValues): void,
    getPaginationData?(): Pagination,
    hasPagination?: boolean,
    isLoading: boolean
}

const TableComponent: React.FC<TableComponentParams> = ({
    className = "",
    renderHeaders,
    renderRows,
    setPaginationData,
    getPaginationData = () => initialPagination,
    hasPagination = true,
    isLoading = true
}) => {
    const headers = renderHeaders();
    const rows = renderRows();

    return (
        <>
            <table className={`${styles.table} ${className}`}>
                <thead className={styles.tableHead}>
                    <tr>
                        {headers}
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        if (isLoading) {
                            return (
                                <tr>
                                    <td
                                        key={0}
                                        className={styles.isLoading}
                                        colSpan={headers.length}
                                    >
                                        Carregando...
                                    </td>
                                </tr>
                            );
                        }

                        if (!isLoading && rows.length == 0) {
                            return (
                                <tr>
                                    <td key={0} colSpan={headers.length}>
                                        Não foram encontrados resultados!
                                    </td>
                                </tr>
                            );
                        }
                        return rows;
                    })()}
                </tbody>
            </table>

            {hasPagination && setPaginationData && (
                <CustomPaginationActions
                    setPaginationData={setPaginationData}
                    getPaginationData={getPaginationData}
                />
            )}
        </>
    );
};

interface CustomPaginationActionsParams {
    getPaginationData(): Pagination,
    setPaginationData(key: PaginationKeys, value: PaginationValues): void
}

const CustomPaginationActions: React.FC<CustomPaginationActionsParams> = ({
    setPaginationData,
    getPaginationData
}) => {
    const paginationData = getPaginationData();
    if (!paginationData) return <></>;
    if (!paginationData?.lastPage || paginationData.lastPage === 1) return <></>;

    const setPage = (newPage: number) => setPaginationData("currentPage", newPage);

    const totalPages = paginationData.lastPage;
    const currentPage = paginationData.currentPage;
    const maxPageButtons = 5;

    const startPage = Math.max(currentPage - Math.floor(maxPageButtons / 2), 1);
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    const pageButtons = [];
    for (let i = startPage; i <= endPage; i++) {
        const pageNumber = i;
        pageButtons.push(
            <button
                key={pageNumber}
                className={`${styles.page} ${currentPage === pageNumber ? styles.currentPage : ""
                    }`}
                onClick={() => setPage(pageNumber)}
            >
                {pageNumber}
            </button>
        );
    }

    return (
        <div className={styles.tablePaginationActions}>
            <button
                className={`${styles.firstLastPageButton} ${currentPage === 1 ? styles.disabled : ""
                    }`}
                onClick={() => setPage(1)}
                disabled={currentPage === 1}
            >
                <img src={doubleArrowLeft} alt="Primeira página" />
            </button>

            <button
                className={`${styles.previousPageButton} ${!paginationData.prev ? styles.disabled : ""
                    }`}
                onClick={() => setPage(paginationData.prev ?? currentPage)}
                disabled={!paginationData.prev}
            >
                <img src={leftArrowIconBlack} alt="" />
            </button>

            {pageButtons}

            <button
                className={`${styles.nextPageButton} ${!paginationData.next ? styles.disabled : ""
                    }`}
                onClick={() => setPage(paginationData.next ?? currentPage)}
                disabled={!paginationData.next}
            >
                <img src={rightArrowIconBlack} alt="" />
            </button>

            <button
                className={`${styles.firstLastPageButton} ${currentPage === totalPages ? styles.disabled : ""
                    }`}
                onClick={() => setPage(totalPages)}
                disabled={currentPage === totalPages}
            >
                <img src={doubleArrowRight} alt="Última página" />
            </button>
        </div>
    );
};

export default TableComponent;
