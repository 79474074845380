import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useAuthContext } from "../../contexts/AuthContext";

const ProtectedRoute = () => {
    const {
        isLoading,
        doLogout,
        validateLogin
    } = useAuthContext();

    const navigate = useNavigate();

    React.useEffect(() => {
        const token = localStorage.getItem("token") ?? "";
        validateLogin(token)
            .then(isValid => {
                if (!isLoading && !isValid) {
                    doLogout();
                    navigate("/login")
                }
            });
    }, []);

    return <Outlet />;
};

export default ProtectedRoute;
