import React from 'react';

import PopupIndicator from '../../../components/popupIndicator/PopupIndicator';
import Dre from '../../../types/Dre';
import styles from './RecebimentosPopup.module.css';
import { formatToKwh } from '../../../utils/formatters';

interface RecebimentosPopupParams {
    data: Dre,
    isLocacao?: boolean,
    isPrint?: boolean
}

const RecebimentosPopup: React.FC<RecebimentosPopupParams> = ({
    data,
    isLocacao = false,
    isPrint = false
}) => {
    const {
        energia_injetada,
        energia_compensada,
        saldo_meses_anteriores
    } = data;

    const energiaInjetadaMaisSaldoMesesAnteriores = energia_injetada + saldo_meses_anteriores;
    const energiaCompensadaMenosSaldo = energia_compensada - energiaInjetadaMaisSaldoMesesAnteriores;

    return (
        <div className={`
            ${styles.recebimentosPopupContent}
            ${isLocacao ? styles.isLocacao : styles.isDividendos}
            ${isPrint ? styles.isPrint : ''}
        `}>
            <div>
                <PopupIndicator className={styles.firstIndicator} />
                <label>{`Da geração é descontado a energia\nconsumida e a retida, resultando\n no valor injetado de `}<strong>{ formatToKwh(energia_injetada) }</strong></label>
            </div>
            <div>
                <PopupIndicator className={styles.secondIndicator} />
                <label>{`O valor injetado é somado ao saldo\nrestante de meses anteriores, o total\né `}<strong>{ formatToKwh(energiaInjetadaMaisSaldoMesesAnteriores) }</strong></label>
            </div>
            <div>
                <PopupIndicator className={styles.thirdIndicator} />
                <label>{`Porém apenas `}<strong>{ formatToKwh(energia_compensada) }</strong>{` foi\ncompensado esse mês, restando\n`}<strong>{ formatToKwh(energiaCompensadaMenosSaldo) }</strong> para os próximos meses</label>
            </div>
            <div>
                <PopupIndicator className={styles.fourthIndicator} isLine={true} />
                <label>{`A receita da usina é calculada\nsobre o valor da energia compensada`}</label>
            </div>
        </div>
    );
}

export default RecebimentosPopup;