import InputSearchOption from '../types/InputSearchOption';
import Usina from '../types/Usina';
import UsinasGateway from './interfaces/UsinasGateway';

export const usinaFake: Usina = {
	id: '123',
	nome: 'Usina 1',
	potencia_instalada: {
		label: '10 MW',
		value: '10000',
	},
	tensao: {
		label: 5,
		value: '5',
	},
	distribuidora_id: {
		label: 'DEVs Unidos',
		value: '123abc',
	},
	modelo_negocio: {
		label: 'Dividendos',
		value: 'dividendos',
	},
	endereco: '0x0ff',
	localizacao_lat: '12.004',
	localizacao_long: '-12.03',
};

export default class UsinasGatewayFake
	implements UsinasGateway
{
	async getUsinas(): Promise<Array<Usina>> {
		return [usinaFake];
	}

	async getUsinasForSelect(): Promise<
		Array<InputSearchOption>
	> {
		// const usinas = [usinaFake];
		const usinas = [
			usinaFake,
			{
				...usinaFake,
				id: 1,
				nome: 'Usina 2',
			},
		];
		const usinasForSelect = usinas.map(
			({ id, nome }) => {
				return {
					label: nome,
					value: id,
				} as unknown as InputSearchOption;
			},
		);
		return usinasForSelect;
	}

	async getUsinaById(
		id: string,
	): Promise<Usina> {
		return usinaFake;
	}
}
