import React from 'react';
import styles from './CardInfoColored.module.css';

interface CardInfoColoredParams {
    color: string,
    label: string,
    value: string
}

const CardInfoColored: React.FC<CardInfoColoredParams> = ({
    color = "",
    label = "",
    value = ""
}) => {
    return (
        <div className={styles.cardInfoColored}>
            <span
                className={styles.colored}
                style={{ backgroundColor: color }}
            ></span>
            <label>{label}</label>
            <span className={styles.value}>{value}</span>
        </div>
    );
}

export default CardInfoColored;