import React from "react";
import styles from "./PrimaryButton.module.css";

interface ButtonProps {
    type?: "button" | "submit",
    label?: string,
    onClick?(): void,
    disable?: boolean,
    className?: string,
    icon?: string,
    hasIconLeft?: boolean,
    hasIconRight?: boolean,
    reverse?: boolean
}

const PrimaryButton: React.FC<ButtonProps> = ({
    type = "button",
    label = "",
    onClick = () => {},
    disable = false,
    className = "",
    icon = "",
    hasIconLeft = false,
    hasIconRight = false,
    reverse = false
}) => {
    return (
        <button
            type={type}
            onClick={disable ? () => {} : onClick}
            className={`${styles.button} ${className} ${
                disable && styles.disable
            } ${reverse ? styles.reverse : ""}`}
            disabled={disable}
        >
            {hasIconLeft && <img src={icon} className={styles.icon} alt="" />}
            {label}
            {hasIconRight && <img src={icon} className={styles.icon} alt="" />}
        </button>
    );
};
export default PrimaryButton;
