import React, { createContext } from "react";
import { PaginationReducer, initialPagination } from "../reducers/PaginationReducer";
import Pagination, { PaginationKeys, PaginationValues } from "../types/Pagination";

interface PaginationContextParams {
    getPagination(): Pagination
    setPaginationProp(prop: PaginationKeys, newValue: PaginationValues): void
}

const PaginationContext = createContext<PaginationContextParams>({
    getPagination: () => initialPagination,
    setPaginationProp: (prop: PaginationKeys, newValue: PaginationValues) => { }
});

export const usePaginationContext = () => React.useContext(PaginationContext);

interface PaginationProviderParams {
    children: React.ReactNode;
}

export const PaginationProvider: React.FC<PaginationProviderParams> = ({ children }) => {
    const [pagination, dispatchPagination] = React.useReducer(PaginationReducer, initialPagination);

    const getPagination = () => {
        return pagination;
    }

    const setPaginationProp = (prop: PaginationKeys, newValue: PaginationValues) => {
        dispatchPagination({
            type: "updateProp",
            key: prop,
            value: newValue
        });
    }

    return (
        <PaginationContext.Provider
            value={{
                getPagination,
                setPaginationProp
            }}
        >
            {children}
        </PaginationContext.Provider>
    );
};
export default PaginationContext;
