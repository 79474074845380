import React from 'react';
import styles from './PopupIndicator.module.css';

interface PopupIndicatorParams {
    className?: string,
    isLine?: boolean
}

const PopupIndicator: React.FC<PopupIndicatorParams> = ({
    className = '',
    isLine = false
}) => {
    return (
        <span className={`${styles.popupIndicator} ${className}`}>
            {!isLine && <i className={styles.block}></i>}
            <i className={`${styles.line} ${isLine && styles.onlyLine}`}></i>
        </span>
    );
}

export default PopupIndicator;