import React from "react";
import styles from "./SpanStatus.module.css";

const SpanStatus = ({
    label = "",
    bgColor = "",
    fontColor = "",
    isFill = false
}) => {
    const spanColor = isFill ?
        {
            color: fontColor,
            backgroundColor: bgColor
        }
        : {
            bgColor,
            color: bgColor
        }

    return (
        <span
            className={styles.span}
            style={{
                borderColor: `${bgColor}`,
                ...spanColor
            }}
        >
            {label}
        </span>
    );
};

export default SpanStatus;
