import axios from "axios";
import React, { createContext } from "react";
import { toast } from "react-toastify";
import LoginGateway from "../gateways/interfaces/LoginGateway";
import { toastOptionsDesktop } from "../utils/utils";

interface AuthContextType {
    doLogin(username: string, password: string): Promise<boolean>,
    doLogout(): void,
    validateLogin(token: string): Promise<boolean>,
    isLoading: boolean
}

const AuthContext = createContext<AuthContextType>({
    doLogin: async (username, password) => false,
    doLogout: () => { },
    validateLogin: async (token) => false,
    isLoading: true
});

export const useAuthContext = () => React.useContext(AuthContext);

interface AuthProviderParams {
    children: React.ReactNode;
    loginGateway: LoginGateway
}

export const AuthProvider: React.FC<AuthProviderParams> = ({
    children,
    loginGateway
}) => {
    const [isLoading, setIsLoading] = React.useState(true);

    async function doLogin(username: string, password: string) {
        setIsLoading(true);

        const loginRequest = loginGateway
            .login(username, password)
            .then(({ access_token }) => {
                const token = access_token;
                localStorage.setItem("token", token);
                localStorage.setItem("modelo_negocio", username == 'dividendos' ? 'dividendos' : 'locacao');
            })
            .finally(() => {
                setIsLoading(false);
            });

        return toast.promise(
            loginRequest,
            {
                pending: "Carregando...",
                success: "Logado com sucesso!",
                error: "Ocorreu um erro, por favor verifique suas credenciais!",
            },
            toastOptionsDesktop
        ).then(_ => true)
            .catch(e => { throw e });
    }

    function doLogout() {
        localStorage.removeItem("token");
        localStorage.removeItem("modelo_negocio");
        axios.defaults.headers.common.Authorization = "";
        setIsLoading(false);
    }

    async function validateLogin(token: string) {
        if (token == "") return false;

        return loginGateway.getUserInfo()
            .then(() => {
                return true;
            })
            .catch(e => {
                toast.error('Por favor, realize o login!');
                throw e;
            });
    }

    return (
        <AuthContext.Provider
            value={{
                doLogin,
                doLogout,
                validateLogin,
                isLoading
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
