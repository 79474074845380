import React from 'react';
import styles from './ChartRentabilidade.module.css';
import LineAverageChart from '../../components/lineChart/LineAverageChart';

const ChartRentabilidade = () => {
    const chartData = {
        y: [1.78, 1.73, 1.09, 1.47, 1.54, 1.99],
        x: ['jan/2023','fev/2023','mar/2023','abr/2023','mai/2023','jun/2023']
    };

    const formatLabels = (label: number | string) => {
        return label + '%';
    }

    return (
        <LineAverageChart
            data={chartData}
            legend={{
                y: 'Rentabilidade'
            }}
            dataLabelFormatter={formatLabels}
        />
    );
}

export default ChartRentabilidade;