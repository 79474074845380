import React from "react";

import { useNavigate } from "react-router-dom";

import styles from "./Login.module.css";

import simboloPlin from "../../assets/plin-simbolo.svg";
import logoPlin from "../../assets/logo-plin-full-icon-blue.svg";
import userIcon from "../../assets/user-icon.svg";
import lockerIcon from "../../assets/locker-icon.svg";
import arrowRight from "../../assets/arrow-right.svg";

import { useAuthContext } from "../../contexts/AuthContext";
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from "../../components/input/Input";
import PrimaryButton from "../../components/primaryButton/PrimaryButton";

type FormValues = {
    username: string;
    password: string;
};

const Login = () => {
    const navigate = useNavigate();

    const {
        doLogin
    } = useAuthContext();

    React.useEffect(() => {
        const token = !!localStorage.getItem('token');
        if (token) navigate('/dre');
    }, []);

    const { register, handleSubmit } = useForm<FormValues>();

    const handleDoLogin: SubmitHandler<FormValues> = async (data, evt) => {
        evt?.preventDefault();
        const { username, password } = data;
        doLogin(username, password)
            .then(() => {
                window.location.href = '/';
            });
    }

    return (
        <section>
            <header className={styles.header}>
                <img src={logoPlin} alt="" />
            </header>

            <div className={styles.login}>
                <div className={styles.inputsContainer}>
                    <img src={simboloPlin} alt="" className={styles.simbolo} />
                    <h2>Login do Investidor</h2>
                    <form
                        className={styles.form}
                        onSubmit={handleSubmit(handleDoLogin)}
                    >
                        <Input
                            register={register('username')}
                            icon={userIcon}
                            placeholder="Username"
                        />
                        <Input
                            register={register('password')}
                            type="password"
                            icon={lockerIcon}
                            placeholder="Password"
                        />
                        <PrimaryButton
                            type="submit"
                            label="entrar"
                            hasIconRight={true}
                            icon={arrowRight}
                        />
                    </form>
                </div>

                <div className={styles.imageContainer}>
                    <div className={styles.imageFilter}></div>
                    <h1>Economia de <strong>energia</strong> simples assim.</h1>
                </div>
            </div>
        </section>
    );
};

export default Login;
