import React, { useState } from "react";
import styles from "./Input.module.css";
import { FieldName, FieldValues, UseFormRegister, UseFormRegisterReturn, useForm } from "react-hook-form";

interface InputProps {
    register?: UseFormRegisterReturn<FieldName<FieldValues>>,
    className?: string,
    icon: string,
    type?: "text" | "number" | "password",
    placeholder?: string,
    value?: string,
    onChange?(newValue: string): void,
    showIcon?: boolean,
    disabled?: boolean
}

const Input: React.FC<InputProps> = ({
    register = {},
    className = "",
    icon = "",
    type = "text",
    placeholder = "",
    value = "",
    onChange = (newValue: string) => { },
    showIcon = true,
    disabled = false
}) => {
    return (
        <div className={`${styles.container} ${className}`}>
            {showIcon && <img src={icon} />}
            {
                register ?
                    <input
                        {...register}
                        disabled={disabled}
                        type={type}
                        placeholder={placeholder}
                    />
                    : <input
                        {...register}
                        disabled={disabled}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={(evt) => onChange(evt.target.value)}
                    />
            }
        </div>
    );
};

export default Input;
