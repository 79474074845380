import React from 'react';

import DoubleStackedBarChart from '../../components/barChart/DoubleStackedBarChart';

const ChartHistoricoCompensacao = () => {
    const chartData = {
        yTop: [1889, 1126, 4407, 107, 844, 1360],
        yBottom: [16390, 12661, 12071, 9000, 14250, 14648],
        x: ['jan/2023', 'fev/2023', 'mar/2023', 'abr/2023', 'mai/2023', 'jun/2023']
    };

    const formatLabels = (label: number | string) => {
        return `${label.toLocaleString('pt-br')} kWh`;
    }

    return (
        <DoubleStackedBarChart
            data={chartData}
            legend={{
                yBottom: 'Energia injetada',
                yTop: 'Saldo'
            }}
            dataLabelFormatter={formatLabels}
        />
    );
}

export default ChartHistoricoCompensacao;