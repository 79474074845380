import React from "react";

import styles from "./SecondaryButton.module.css";

interface SecondaryButtonParams {
    label?: string,
    onClick?(): void,
    disable?: boolean,
    className?: string,
    icon?: string,
    hasIconLeft?: boolean,
    hasIconRight?: boolean,
    reverse?: boolean
}

const SecondaryButton: React.FC<SecondaryButtonParams> = ({
    label = "",
    onClick = () => {},
    disable = false,
    className = "",
    icon = "",
    hasIconLeft = false,
    hasIconRight = false,
    reverse = false
}) => {
    return (
        <button
            onClick={disable ? () => {} : onClick}
            className={`${styles.button} ${className} ${
                disable && styles.disable
            } ${reverse ? styles.reverse : ""}`}
            disabled={disable}
        >
            {hasIconLeft && <img src={icon} className={styles.icon} alt="" />}
            {label}
            {hasIconRight && <img src={icon} className={styles.icon} alt="" />}
        </button>
    );
};

export default SecondaryButton;
