import React from "react";
import { Outlet } from "react-router-dom";

import styles from "./Container.module.css";

import DrawerMenu from "../drawerMenu/DrawerMenu";
import Header from "../header/Header";

const Container: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className={`${styles.container}  ${isOpen ? styles.isOpen : ""}`}>
            <DrawerMenu isOpen={isOpen} setIsOpen={setIsOpen} />
            <Header isOpen={isOpen} />
            <div className={styles.content}>
                <Outlet />
            </div>
        </div>
    );
};

export default Container;
