import React from "react";

import LineChart from "./LineChartComponent";

interface LineAverageChartParams {
  data: {
    y: Array<number>;
    x: Array<number | string>;
  };
  legend: {
    y: string;
  };
  dataLabelFormatter?(label: string): void;
}

const LineAverageChart: React.FC<LineAverageChartParams> = ({
  data = {
    y: [],
    x: [],
  },
  legend = {
    y: "",
  },
  dataLabelFormatter = (label: string) => {},
}) => {
  const getAverage = (data: Array<number>) => {
    const average = (
      data.reduce((acc, item) => acc + item, 0) / data.length
    ).toFixed(2);
    const averageArray = data.map((_) => average);
    return averageArray;
  };

  const options = {
    type: "line",
    elements: {
      line: {
        tension: 0.25,
      },
    },
    plugins: {
      responsive: true,
      datalabels: { display: false },
      legend: {
        display: true,
        align: "end",
        position: "top",
      },
      tooltip: {
        yAlign: "top",
        callbacks: {
          label: (y: any) => `${y.dataset.label}: ` + dataLabelFormatter(y.raw),
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        border: { display: false },
      },
      y: {
        stacked: true,
        grid: { display: false },
        border: { display: false },
        ticks: {
          callback: (value: string) => dataLabelFormatter(value),
        },
      },
    },
  };

  const chartData = {
    labels: data.x,
    datasets: [
      {
        type: "line",
        label: legend.y,
        data: data.y,
        borderColor: "#0047BA",
        backgroundColor: "#0047BA",
      },
      {
        type: "scatter",
        label: "Média",
        showLine: true,
        data: getAverage(data.y),
        borderColor: "#F5E100",
        backgroundColor: "#F5E100",
        datalabels: { display: false },
        elements: {
          line: {
            tension: 0,
          },
        },
      },
    ],
  };

  return <LineChart data={chartData} options={options} />;
};

export default LineAverageChart;
