export type BillStatusType = keyof typeof BillStatus;
export const BillStatus = {
    SIM: {
        color: "var(--verde-positivo)",
        label: "Paga",
        value: true,
    },
    NAO: {
        color: "var(--vermelho-erro)",
        label: "Não Paga",
        value: false,
    }
};
