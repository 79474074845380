import FaturaBeneficiaria from '../types/Beneficiarias';
import { Filtros } from '../types/Filtros';
import BeneficiariasGateway from './interfaces/BeneficiariasGateway';

const faturasBeneficiariasFake: Array<FaturaBeneficiaria> =
	[
		{
			id: '123',
			data_vencimento: new Date(
				'2023-11-7',
			),
			data_pagamento: new Date(),
			valor_compensado: 100,
			kwh_compensado: 100,
			beneficiaria_id: '123',
			data_ref: new Date(),
			uc: '285210',
			file_key: 'DEVKey',
			kwh_acumulado_fp: 10,
			kwh_acumulado_p: 10,
			kwh_billed: 100,
			kwh_consumido: 3492,
			micro_mini_geracao: false,
			valor_energia: 2000,
			energia_injetada_kwh: 3645,
			energia_injetada_reais: 1979.964,
			saldo_kwh: 153,
			saldo_reais: 86.751,
		},
		{
			id: '123',
			data_vencimento: new Date(),
			data_pagamento: new Date(),
			valor_compensado: 100,
			kwh_compensado: 100,
			beneficiaria_id: '123',
			data_ref: new Date(),
			uc: '635003',
			file_key: 'DEVKey',
			kwh_acumulado_fp: 10,
			kwh_acumulado_p: 10,
			kwh_billed: 100,
			kwh_consumido: 1461,
			micro_mini_geracao: false,
			valor_energia: 2000,
			energia_injetada_kwh: 1548,
			energia_injetada_reais: 828.387,
			saldo_kwh: 87,
			saldo_reais: 49.329,
		},
		{
			id: '123',
			data_vencimento: new Date(),
			data_pagamento: new Date(),
			valor_compensado: 100,
			kwh_compensado: 100,
			beneficiaria_id: '123',
			data_ref: new Date(),
			uc: '648139',
			file_key: 'DEVKey',
			kwh_acumulado_fp: 10,
			kwh_acumulado_p: 10,
			kwh_billed: 100,
			kwh_consumido: 1245,
			micro_mini_geracao: false,
			valor_energia: 2000,
			energia_injetada_kwh: 1365,
			energia_injetada_reais: 705.915,
			saldo_kwh: 120,
			saldo_reais: 68.04,
		},
		{
			id: '123',
			data_vencimento: new Date(),
			data_pagamento: null,
			valor_compensado: 100,
			kwh_compensado: 100,
			beneficiaria_id: '123',
			data_ref: new Date(),
			uc: '752033',
			file_key: 'DEVKey',
			kwh_acumulado_fp: 10,
			kwh_acumulado_p: 10,
			kwh_billed: 100,
			kwh_consumido: 2350,
			micro_mini_geracao: false,
			valor_energia: 2000,
			energia_injetada_kwh: 2500,
			energia_injetada_reais: 1332.45,
			saldo_kwh: 150,
			saldo_reais: 85.05,
		},
		{
			id: '123',
			data_vencimento: new Date(),
			data_pagamento: new Date(),
			valor_compensado: 100,
			kwh_compensado: 100,
			beneficiaria_id: '123',
			data_ref: new Date(),
			uc: '884149',
			file_key: 'DEVKey',
			kwh_acumulado_fp: 10,
			kwh_acumulado_p: 10,
			kwh_billed: 100,
			kwh_consumido: 4500,
			micro_mini_geracao: false,
			valor_energia: 2000,
			energia_injetada_kwh: 4729,
			energia_injetada_reais: 2551.5,
			saldo_kwh: 229,
			saldo_reais: 129.843,
		},
	];

export default class BeneficiariasGatewayFake
	implements BeneficiariasGateway
{
	async getCobrancasBeneficiarias(
		filtros: Filtros,
	): Promise<FaturaBeneficiaria[]> {
		return faturasBeneficiariasFake;
	}
}
