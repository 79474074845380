import React from "react";

import BarChart from "./BarChartComponent";

interface SingleBarChartParams {
  data: {
    y: Array<number>;
    x: Array<number | string>;
  };
  legend: {
    y: string;
  };
  dataLabelFormatter?(label: string): string;
  isHorizontal?: boolean;
  showLabels?: boolean;
  showLegend?: boolean;
  showBorder?: boolean;
}

const SingleBarChart: React.FC<SingleBarChartParams> = ({
  data = {
    y: [],
    x: [],
  },
  legend = {
    y: "",
  },
  dataLabelFormatter = (label) => {},
  isHorizontal = false,
  showLabels = true,
  showLegend = true,
  showBorder = true,
}) => {
  const options = {
    responsive: true,
    indexAxis: isHorizontal ? "y" : "x",
    layout: {
      padding: {
        top: 30,
      },
    },
    plugins: {
      datalabels: {
        display: showLabels,
        align: "top",
        anchor: "end",
        font: {
          weight: "bold",
          size: 12,
        },
        formatter: function (value: string) {
          return dataLabelFormatter(value);
        },
      },
      legend: {
        display: !showLegend && !showLabels,
        align: "end",
        position: "top",
      },
    },
    scales: {
      x: {
        display: showLabels,
        grid: { display: false, tickLength: 0 },
        border: { display: showBorder },
      },
      y: {
        display: showLabels,
        grid: { display: false, tickLength: 0 },
        border: { display: showBorder },
        ticks: {
          callback: (value: string) => dataLabelFormatter(value),
        },
      },
    },
  };

  const chartData = {
    labels: data.x,
    datasets: [
      {
        label: legend.y,
        data: data.y,
        backgroundColor: "#0047BA",
        datalabels: {
          labels: {
            title: {
              color: "#2a2a2a",
            },
          },
        },
      },
    ],
  };

  return <BarChart data={chartData} options={options} height="100%" />;
};

export default SingleBarChart;
