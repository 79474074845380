import React, { createContext } from "react";
import DreGateway from "../gateways/interfaces/DreGateway";
import Dre, { Bandeira, StatusDRE } from "../types/Dre";
import Usina from "../types/Usina";
import { useFiltrosContext } from "./FiltrosContext";

const emptyDre: Dre = {
    id: "",
    usina_id: "",
    usina: {} as Usina,
    mes_ref: new Date(),
    bandeira: {} as Bandeira,
    status_dre: {} as StatusDRE,
    geracao: 0,
    consumo: 0,
    energia_retida: 0,
    energia_injetada: 0,
    saldo: 0,
    saldo_meses_anteriores: 0,
    energia_compensada: 0,
    receita_usina: 0,
    valor_pendente: 0,
    valor_recebido: 0,
    recebido_atraso: 0,
    juros_multa: 0,
    faturamento_bruto: 0,
    fatura_copel: 0,
    gestao_plin: 0,
    total_copel_gestao_plin: 0,
    receita_tributavel_usina: 0,
    pis_cofins: 0,
    despesas: 0,
    ebtida: 0,
    csll: 0,
    irpj: 0,
    irpj_adicional: 0,
    total_tributos: 0,
    total_dividendos: 0,
    dividendos_proporcao: 0,
    previsao_pagamento: new Date(),
    pago: false,
    rentabilidade: 0,
    despesas_financeiras: 0,
    outras_despesas: 0,
    despesas_oem: 0,
    arrendamento_terreno: 0,
    seguro_usina: 0
};

interface DreContextParams {
    getData(): Promise<void>;
    dreData: Dre
}

const DreContext = createContext<DreContextParams>({
    getData: async () => { },
    dreData: emptyDre
});

export const useDreContext = () => React.useContext(DreContext);

interface DreProviderParams {
    children: React.ReactNode;
    dreGateway: DreGateway;
}

export const DreProvider: React.FC<DreProviderParams> = ({
    children,
    dreGateway
}) => {
    const { getFiltros } = useFiltrosContext();
    const [dreData, setDreData] = React.useState<Dre>(emptyDre);

    const getData = async () => {
        const filtros = getFiltros();
        const data = await dreGateway.getData(filtros);
        setDreData(data);
    }

    return (
        <DreContext.Provider
            value={{
                getData,
                dreData
            }}
        >
            {children}
        </DreContext.Provider>
    );
};
export default DreContext;
