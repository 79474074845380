import React from 'react';
import styles from './CardInfo.module.css';

interface CardInfoParams {
    label: string,
    value: string,
    isBold?: boolean,
    isValueBold?: boolean,
    button?: React.ReactNode
}

const CardInfo: React.FC<CardInfoParams> = ({
    label = "",
    value = "",
    isBold = true,
    isValueBold = false,
    button
}) => {
    return (
        <div className={styles.cardInfo}>
            <label className={isBold ? styles.isBold : ''}>{label}</label>
            <div className={styles.valueDiv}>
                <span className={`${styles.value} ${isValueBold ? styles.isBold : ''}`}>{value}</span>
                {button}
            </div>
        </div>
    );
}

export default CardInfo;