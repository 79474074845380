import React from 'react';

import TripleStackedBarChart from '../../components/barChart/TripleStackedBarChart';

const ChartGeracao = () => {
    const chartData = {
        yTop: [16752, 15600, 13616, 12446, 13741, 17964],
        yMiddle: [115, 124, 109, 168, 136, 114],
        yBottom: [100, 100, 100, 100, 100, 100],
        x: ['jan/2023','fev/2023','mar/2023','abr/2023','mai/2023','jun/2023']
    };

    const formatLabels = (label: number | string) => {
        return `${label.toLocaleString('pt-br')} kWh`;
    }

    return (
        <TripleStackedBarChart
            data={chartData}
            legend={{
                yBottom:'Retida',
                yMiddle:'Consumida',
                yTop:'Energia injetada'
            }}
            dataLabelFormatter={formatLabels}
        />
    );
}

export default ChartGeracao;