
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Container from "./components/container/Container";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import Documentos from "./pages/documentos/Documentos";
import Dre from "./pages/dre/Dre";
import PrintDre from "./pages/dre/print/PrintDre";
import Login from "./pages/login/Login";
import Usinas from "./pages/usinas/Usinas";

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />

                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Container />}>
                        <Route index element={<Navigate to="/dre" />} />
                        <Route path="dre" element={<Dre />} />
                        <Route path="usinas" element={<Usinas />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="documentos" element={<Documentos />} />
                    </Route>
                    <Route path="/print-dre" element={<PrintDre />}/>
                </Route>
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;
