import React from 'react';

import DreLocacao from './DreLocacao';
import DreDividendos from './DreDividendos';
import { useDreContext } from '../../contexts/DreContext';

const Dre = () => {
    const modeloDeContrato = localStorage.getItem('modelo_negocio');
    return modeloDeContrato == 'locacao' ? <DreLocacao /> : <DreDividendos />;
}

export default Dre;