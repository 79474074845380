import LoginGateway, { LoginReturn, UserInfoReturn } from "./interfaces/LoginGateway";

export default class LoginGatewayFake implements LoginGateway {
    async login(username: string, password: string): Promise<LoginReturn> {
        return {
            access_token: 'bearer 123'
        }
    };

    async getUserInfo(): Promise<UserInfoReturn> {
            return {
                userId: 'abc123',
                username: 'user',
                roles: 'ADMIN'
            }
    };
}