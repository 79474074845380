export const formatToKwh = (kwh: number | undefined) => {
    if (!kwh) return "NC";
    return `${kwh.toLocaleString('pt-BR')} kWh`;
}

export const formatToCurrency = (value: number | undefined) => {
    if (value == null) return "NC";
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export const formatToPercentage = (value: number | undefined) => {
    if (!value) return "NC";
    return `${value}%`;
}

export const formatToDateToString = (date: Date | undefined) => {
    if (!date) return "NC";
    return date.toLocaleDateString('pt-BR');
}

export const formatToDateToDayMonthString = (date: Date | undefined) => {
    if (!date) return "NC";
    return date.toLocaleDateString('pt-BR', { day: "2-digit", month: "2-digit" });
}
