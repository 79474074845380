import React, { ReactNode } from 'react';

import styles from './InfoButton.module.css';

import infoIconBlack from '../../assets/info-icon-black.svg';

interface InfoButtonParams {
    paragraph?: string | ReactNode,
    positionX?: 'left' | 'right',
    positionY?: 'top' | 'bottom'
}

const InfoButton: React.FC<InfoButtonParams> = ({
    paragraph = "",
    positionX = 'right',
    positionY = 'bottom'
}) => {
    return (
        <button className={styles.infoButton}>
            <img src={infoIconBlack} alt="Saber mais" />
            <div className={`${styles.popup} ${styles[positionX]} ${styles[positionY]}`}>
                <div>{paragraph}</div>
            </div>
        </button>
    );
}

export default InfoButton;