import React from 'react';

import styles from './Usinas.module.css';

import { ReactComponent as homeBuildingIconBlack } from '../../assets/home-building-icon-black.svg';
import { ReactComponent as lightningBoltIconBlack } from '../../assets/lightning-bolt-icon-black.svg';

import Actions from '../../components/actions/Actions';
import Card from '../../components/card/Card';
import CardInfoColored from '../../components/cardInfoColored/CardInfoColored';
import InfoButton from '../../components/infoButton/InfoButton';
import InputSelect from '../../components/inputSelect/InputSelect';
import ChartBeneficiarias from './chartBeneficiarias/ChartBeneficiarias';
import MovimentacoesPopup from './movimentacoesPopup/MovimentacoesPopup';
import TableCobrancas from './tableCobrancas/TableCobrancas';
import { useUsinasContext } from '../../contexts/UsinasContext';
import { useFiltrosContext } from '../../contexts/FiltrosContext';
import { useDreContext } from '../../contexts/DreContext';
import { formatToKwh } from '../../utils/formatters';
import InputSearchOption from '../../types/InputSearchOption';
import { useBeneficiariasContext } from '../../contexts/BeneficiariasContext';

const Usinas = () => {
    const {
        getCobrancasBeneficiarias,
        cobrancasBeneficiarias,
        getCobrancasPagas,
        beneficiariasPagas
    } = useBeneficiariasContext();

    const {
        getFiltros,
        setFiltroProp
    } = useFiltrosContext();

    const { bill_status } = getFiltros();

    const billStatusOptions: Array<InputSearchOption> = [
        {
            value: '',
            label: 'Todos'
        },
        {
            value: false,
            label: 'Não'
        },
        {
            value: true,
            label: 'Sim'
        }
    ];

    React.useEffect(() => {
        getCobrancasPagas();
    }, []);

    React.useEffect(() => {
        getCobrancasBeneficiarias();
    }, [bill_status]);

    const {
        dreData
    } = useDreContext();

    const compensadaMenosSaldo = dreData.energia_compensada - dreData.saldo;

    return (
        <main>
            <Actions />
            <section className={styles.cards}>
                <div>
                    <Card
                        title='Movimentações'
                        icon={lightningBoltIconBlack}
                        classNameCard={styles.cardMovimentacoes}
                        classNameContent={styles.cardContent}
                        button={<InfoButton
                            paragraph={<MovimentacoesPopup dreData={dreData} />}
                        />}
                    >
                        <div className={styles.values}>
                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Geração"
                                    value={formatToKwh(dreData.geracao)}
                                />
                                <CardInfoColored
                                    color="var(--vermelho-erro)"
                                    label="Consumo da usina"
                                    value={formatToKwh(dreData.consumo)}
                                />
                                <CardInfoColored
                                    color="var(--vermelho-erro)"
                                    label="Energia retida"
                                    value={formatToKwh(dreData.energia_retida)}
                                />
                            </div>
                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Injetada"
                                    value={formatToKwh(dreData.energia_injetada)}
                                />
                            </div>
                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--azul-1)"
                                    label="Compensada"
                                    value={`${formatToKwh(compensadaMenosSaldo)} / ${formatToKwh(dreData.energia_compensada)}`}
                                />
                                <CardInfoColored
                                    color="var(--amarelo)"
                                    label="Saldo"
                                    value={formatToKwh(dreData.saldo)}
                                />
                            </div>
                        </div>
                    </Card>
                    <Card
                        icon={homeBuildingIconBlack}
                        title='Beneficiárias'
                        classNameContent={`${styles.cardContent} ${styles.cardBeneficiarias}`}
                    >
                        <CardInfoColored
                            color="var(--amarelo)"
                            label="Beneficiárias cadastradas"
                            value={cobrancasBeneficiarias.length.toString()}
                        />
                        <CardInfoColored
                            color="var(--azul-1)"
                            label="Beneficiárias que pagaram"
                            value={`${beneficiariasPagas}/${cobrancasBeneficiarias.length}`}
                        />
                        <ChartBeneficiarias
                            cobrancasPagas={beneficiariasPagas}
                            cobrancasNaoPagas={cobrancasBeneficiarias.length - beneficiariasPagas}
                        />
                    </Card>
                </div>

                <Card
                    classNameCard={styles.cardCobrancas}
                    hasHeader={false}
                >
                    <div className={styles.header}>
                        <div className={styles.filtro}>
                            <label>
                                Filtrar cobranças:
                            </label>
                            <InputSelect
                                className={styles.inputFiltroCobrancas}
                                options={billStatusOptions}
                                value={bill_status}
                                onChange={value => setFiltroProp('bill_status', value)}
                                isSearchable={false}
                            />
                        </div>
                        <label className={styles.totalCompensacaoLabel}>Total de compensação: 13.407 kWh</label>
                    </div>
                    <TableCobrancas />
                </Card>
            </section>
        </main>
    );
}

export default Usinas;