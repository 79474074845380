import React, { createContext } from "react";
import { FiltrosReducer, initialFiltros } from "../reducers/FiltrosReducer";
import { Filtros, FiltrosKeys, FiltrosValues } from "../types/Filtros";

interface FiltrosContextParams {
    getFiltros(): Filtros
    setFiltroProp(prop: FiltrosKeys, newValue: FiltrosValues): void
}

const FiltrosContext = createContext<FiltrosContextParams>({
    getFiltros: () => initialFiltros,
    setFiltroProp: (prop: FiltrosKeys, newValue: FiltrosValues) => { }
});

export const useFiltrosContext = () => React.useContext(FiltrosContext);

interface FiltrosProviderParams {
    children: React.ReactNode;
}

export const FiltrosProvider: React.FC<FiltrosProviderParams> = ({ children }) => {
    const [filtros, dispatchFiltro] = React.useReducer(FiltrosReducer, initialFiltros);

    const getFiltros = () => {
        return filtros;
    }

    const setFiltroProp = (prop: FiltrosKeys, newValue: FiltrosValues) => {
        dispatchFiltro({
            type: "updateProp",
            key: prop,
            value: newValue
        });
    }

    return (
        <FiltrosContext.Provider
            value={{
                getFiltros,
                setFiltroProp
            }}
        >
            {children}
        </FiltrosContext.Provider>
    );
};
export default FiltrosContext;
