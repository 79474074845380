import React from 'react';

import styles from './ChartBeneficiarias.module.css';

import SingleStackedBarChart from '../../../components/barChart/SingleStackedBarChart';

interface ChartBeneficiariasParams {
    cobrancasPagas: number,
    cobrancasNaoPagas: number
}

const ChartBeneficiarias: React.FC<ChartBeneficiariasParams> = ({
    cobrancasPagas,
    cobrancasNaoPagas
}) => {
    const data = {
        yLeft: [cobrancasPagas],
        yRight: [cobrancasNaoPagas],
        x: [0]
    };

    const legend = {
        yLeft: 'Beneficiárias que pagaram',
        yRight: 'Beneficiárias cadastradas'
    }

    let chartWidth = '350px';
    React.useEffect(() => {
        const element = document.getElementById("cardBeneficiarias");
        if (!element) return;
        chartWidth = `${element.clientWidth - 80}px`;
    }, []);

    return (
        <div className={styles.wrapper}>
            <SingleStackedBarChart
                data={data}
                legend={legend}
                isHorizontal={true}
                height='16px'
                // width={chartWidth}
                showLabels={false}
                showBorder={false}
                hasHoverAnimation={false}
            />
        </div>
    );
}

export default ChartBeneficiarias;