import React from 'react';

import styles from './Dashboard.module.css';

import {ReactComponent as chartThreeIconBlack} from '../../assets/charts-three-icon-black.svg'
import {ReactComponent as investmentArrowIconBlack} from '../../assets/investment-arrow-icon-black.svg'
import {ReactComponent as coinDollarIconBlack} from '../../assets/coin-dollar-icon-black.svg'
import {ReactComponent as dollarCycleIconBlack} from '../../assets/dollar-cycle-icon-black.svg'

import Actions from '../../components/actions/Actions';
import Card from '../../components/card/Card';
import InfoButton from '../../components/infoButton/InfoButton';
import ChartGeracao from './ChartGeracao';
import SpanStatus from '../../components/spanStatus/SpanStatus';
import ChartPaybackProjetado from './ChartPaybackProjetado';
import ChartHistoricoCompensacao from './ChartHistoricoCompensacao';
import ChartDividendos from './ChartDividendos';
import ChartRentabilidade from './ChartRentabilidade';
import TableFluxoDeCaixa from './TableFluxoDeCaixa';
import { useDashboardContext } from '../../contexts/DashboardContext';

const Dashboard = () => {
    const {
        getData,
        dashboardData
    } = useDashboardContext();

    React.useEffect(() => {
        getData()
    }, []);

    return (
        <main>
            <Actions />

            <section className={styles.cards}>
                <div>
                    <Card
                        icon={chartThreeIconBlack}
                        title='Geração'
                    >
                        <ChartGeracao />
                    </Card>

                    <Card
                        icon={chartThreeIconBlack}
                        title='Histórico de compensação'
                    >
                        <ChartHistoricoCompensacao />
                    </Card>

                    <Card
                        icon={investmentArrowIconBlack}
                        title='Dividendos'
                    >
                        <ChartDividendos /> 
                    </Card>
                </div>

                <div>
                    <Card
                        icon={coinDollarIconBlack}
                        title='Rentabilidade'
                    >
                        <ChartRentabilidade />
                    </Card>
                    <Card
                        icon={investmentArrowIconBlack}
                        classNameContent={styles.cardContent}
                        title='Payback projetado'
                        paragraph='R$ 400.000,00/R$ 4.000,00 por mês, resultando em:'
                        button={<InfoButton
                            paragraph={`O Payback projetado é calculado da\nseguinte forma: CAPEX da sua usina divido\npelo seu dividendo projetado\n(CAPEX/dividendo projetado = Payback projetado).`}
                            positionX='left'
                        />}
                    >
                        <h2>100 meses (8,3 anos)</h2>
                    </Card>
                    <Card
                        icon={investmentArrowIconBlack}
                        classNameContent={styles.cardContent}
                        title='Payback corrigido'
                        paragraph={`R$ 400.000,00 / R$ 5.000,00`}
                        button={<InfoButton
                            paragraph={`O Payback corrigido é calculado da\nseguinte forma: CAPEX da sua usina divido\npela média histórica dos seus dividendos\n(CAPEX/dividendo médio = Payback corrigido).`}
                            positionX='left'
                        />}
                    >
                        <div className={styles.paybackValuesSeparated}>
                            <h2>80 meses (6,6 anos)</h2>
                            <SpanStatus
                                fontColor='var(--branco)'
                                bgColor='var(--verde-positivo)'
                                label='+19,98%'
                                isFill={true}
                            />
                        </div>
                        <ChartPaybackProjetado />
                    </Card>

                    <Card
                        icon={dollarCycleIconBlack}
                        classNameContent={styles.cardTable}
                        title='Fluxo de caixa'
                    >
                        <TableFluxoDeCaixa />
                    </Card>
                </div>
            </section>
        </main>
    );
}

export default Dashboard;