import React from 'react';

import SingleBarChart from '../../components/barChart/SingleBarChart';

const ChartDividendos = () => {
    const chartData = {
        y: [6234.19, 6064.8, 3830.4, 5137.43, 5388.52, 6975.58],
        x: ['jan/2023','fev/2023','mar/2023','abr/2023','mai/2023','jun/2023']
    };

    const formatLabels = (label: number | string) => {
        return label.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
    }

    return (
        <SingleBarChart
            data={chartData}
            legend={{
                y:'Dividendos',
            }}
            dataLabelFormatter={formatLabels}
            showBorder={false}
            showLegend={false}
        />
    );
}

export default ChartDividendos;