import React from "react";
import ReactDatePicker from "react-datepicker";

import calendarIconBlack from "../../assets/calendar-icon-black.svg";

import styles from "./InputMonthYear.module.css";
import { ptBR } from "date-fns/locale";

interface InputMonthYearParams {
    label: string,
    inputLabel: string,
    minDate: string,
    maxDate: Date,
    value: Date,
    onChange(newValue: Date): void
}

const InputMonthYear = ({
    label = "",
    inputLabel = "",
    minDate = "2021-08-01",
    maxDate = new Date(),
    value = new Date(),
    onChange = (newValue: Date) => {}
}) => {
    return (
        <div className={styles.inputMonthYear}>
            {label.length > 0 ? <label>{label}</label> : ""}
            <img src={calendarIconBlack} alt="Escolher uma data" />
            <ReactDatePicker
                className={styles.inputMonthYearInput}
                placeholderText="Mês de Referência"
                locale={ptBR}
                selected={value}
                onChange={onChange}
                dateFormat="MMMM/yyyy"
                showFullMonthYearPicker={true}
                showMonthYearPicker={true}
                showIcon={false}
                showFourColumnMonthYearPicker={true}
            />
        </div>
    );
};

export default InputMonthYear;
