import React from 'react';

import styles from './PrintDreLocacao.module.css';

import Card from '../../../components/card/Card';

import logoPlinFullIconWhite from '../../../assets/logo-plin-full-icon-white.svg'
import coinDollarIconBlack from '../../../assets/coin-dollar-icon-black.svg'
import toolsIconBlack from '../../../assets/tools-icon-black.svg'
import bankIconBlack from '../../../assets/bank-icon-black.svg'
import investmentArrowIconWhite from '../../../assets/investment-arrow-icon-white.svg'
import handCoinIconBlack from '../../../assets/hand-coin-icon-black.svg'

import CardInfoColored from '../../../components/cardInfoColored/CardInfoColored';
import SpanStatus from '../../../components/spanStatus/SpanStatus';
import InfoButton from '../../../components/infoButton/InfoButton';
import CardInfo from '../cardInfo/CardInfo';
import RecebimentosPopup from '../recebimentosDividendosPopup/RecebimentosPopup';
import { useDreContext } from '../../../contexts/DreContext';

const PrintDreLocacao = () => {
    const { dreData } = useDreContext();

    const previsaoDePagamentoStatus = {
        bgColor: 'var(--verde-positivo)',
        fontColor: 'var(--branco)',
        isFill: true,
        label: 'Pago'
    }

    React.useEffect(() => {
        // TODO: when got the context working, two possibilities:
        // - do the GET on useEffect and on `then` do window.print()
        // - call this page passing all data as params (try POST)
        setTimeout(() => window.print(), 500);

        window.onafterprint = () => {
            window.close()
        }
    }, [window.print]);

    return (
        <main>
            <div className={styles.hidder}></div>
            <header className={styles.header}><img src={logoPlinFullIconWhite} /></header>

            <section className={styles.cards}>
                <div className={styles.column}>
                    <Card
                        title='Recebimentos'
                        icon={coinDollarIconBlack}
                        classNameContent={`${styles.cardContent} ${styles.cardRecebimentos}`}
                        button={<InfoButton
                            paragraph={<RecebimentosPopup isLocacao={true} data={dreData} />}
                        />}
                    >
                        <div className={styles.values}>
                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Geração"
                                    value="13.616 kWh"
                                />
                                <CardInfoColored
                                    color="var(--vermelho-erro)"
                                    label="Consumida"
                                    value="109 kWh"
                                />
                                <CardInfoColored
                                    color="var(--vermelho-erro)"
                                    label="Energia retida"
                                    value="100 kWh"
                                />
                            </div>

                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Injetada"
                                    value="13.407 kWh"
                                />
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Saldo meses anteriores"
                                    value="10.225 kWh"
                                />
                            </div>

                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--azul-1)"
                                    label="Compensada"
                                    value="22.541 kWh"
                                />
                                <CardInfoColored
                                    color="var(--amarelo)"
                                    label="Saldo"
                                    value="1.491 kWh"
                                />
                            </div>
                        </div>

                        <div className={styles.total}>
                            <label>Receita da Usina</label>
                            <span>R$ 11.946,73</span>
                        </div>
                    </Card>

                    <Card
                        title='Faturamento'
                        icon={handCoinIconBlack}
                        classNameContent={styles.cardContent}
                        button={<InfoButton
                            paragraph={<ul className={styles.popupFaturamento}>
                                <li>A receita da usina é resultado da<br /><strong>soma do valor recebido, recebidos<br />com atraso e juros e multas.</strong></li>

                                <li>O <strong>valor pendente</strong> é referente a<br />cobranças de beneficiários que não<br />foram liquidadas até a data do<br />pagamento.</li>
                            </ul>}
                        />}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="Valor pendente"
                                value="R$ 2.000,00"
                            />
                            <CardInfo
                                label="Valor recebido"
                                value="R$ 9.946,73"
                            />
                            <CardInfo
                                label="Recebidos com atraso"
                                value="R$ 1.395,48"
                            />
                            <CardInfo
                                label="Juros e Multas"
                                value="R$ 453,27"
                            />
                        </div>

                        <div className={styles.total}>
                            <label>Total</label>
                            <span>R$ 11.795,48</span>
                        </div>
                    </Card>

                    <Card
                        title='Projeção de Receita Líquida'
                        hasIcon={false}
                        isPrimary={false}
                        paragraph={`Projeção dos dividendos considerando\n o regime tributário do seu CNPJ`}
                    />

                    <Card
                        title='Deduções e Despesas'
                        icon={toolsIconBlack}
                        isPrimary={false}
                        classNameContent={`${styles.cardContent} ${styles.deducoesDespesas}`}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="PIS/COFINS"
                                value="-R$ 361,81"
                            />
                            <CardInfo
                                label="Despesas"
                                value="R$ 0,00"
                                isBold={false}
                            />
                            <CardInfo
                                label="Despesas com O&M"
                                value="R$ 0,00"
                                isBold={false}
                            />
                            <CardInfo
                                label="Arrendamento do Terreno"
                                value="R$ 0,00"
                                isBold={false}
                            />
                            <CardInfo
                                label="Seguro da Usina"
                                value="R$ 0,00"
                                isBold={false}
                            />
                            <CardInfo
                                label="Despesas Gerais e Extras"
                                value="R$ 0,00"
                                isBold={false}
                            />
                        </div>
                        <div className={styles.total}>
                            <label>EBITDA</label>
                            <span>R$ 9.550,82</span>
                        </div>
                    </Card>

                    <Card
                        icon={investmentArrowIconWhite}
                        title='Rentabilidade Mensal do Investidor'
                        button={<span className={styles.rentabilitySpan}>2.51%</span>}
                        isPrimary={false}
                        classNameCard={styles.cardRentability}
                    ></Card>
                </div>

                <div className={styles.column}>
                    <Card hasHeader={false} classNameCard={styles.popupCard}>
                        <RecebimentosPopup isPrint={true} data={dreData} />
                    </Card>

                    <Card
                        title='Despesas do Consórcio'
                        icon={handCoinIconBlack}
                        classNameContent={styles.cardContent}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="Seguro da Usina"
                                value="R$ 113,53"
                            />
                            <CardInfo
                                label="Despesas Gerais e Extras"
                                value="R$ 1.769,32"
                            />
                        </div>

                        <div className={styles.total}>
                            <label>Total</label>
                            <span>R$ 1.882,85</span>
                        </div>
                    </Card>
                    <Card
                        title='Receita Tributável da Usina - SPE'
                        icon={coinDollarIconBlack}
                        classNameContent={styles.cardContent}
                    >
                        <div className={styles.values}>
                            <div className={styles.total}>
                                <label>Locação</label>
                                <span>R$ 9.912,63</span>
                            </div>
                            <div className={styles.previsaoPagamento}>
                                <label>Previsão de pagamento: 10/02</label>
                                <SpanStatus
                                    {...previsaoDePagamentoStatus}
                                />
                            </div>
                        </div>
                    </Card>

                    <Card
                        title='Tributos'
                        icon={bankIconBlack}
                        isPrimary={false}
                        classNameContent={styles.cardContent}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="CSLL"
                                value="-R$ 285, 48"
                            />
                            <CardInfo
                                label="IRPJ"
                                value="-R$ 475,81"
                            />
                            <CardInfo
                                label="IRPJ Adicional"
                                value="R$ 0,00"
                            />
                        </div>

                        <div className={styles.total}>
                            <label>Total</label>
                            <span>-R$ 761,29</span>
                        </div>
                    </Card>
                    <Card
                        title='Resultado Líquido da Usina'
                        icon={coinDollarIconBlack}
                        isPrimary={false}
                        classNameContent={styles.cardContent}
                    >
                        <div className={styles.total}>
                            <label>Dividendos</label>
                            <span>R$ 8.789,53</span>
                        </div>
                    </Card>
                </div>
            </section>
        </main>
    );
}

export default PrintDreLocacao;