import styles from './DreLocacao.module.css';

import { ReactComponent as bankIconBlack } from '../../assets/bank-icon-black.svg';
import { ReactComponent as coinDollarIconBlack } from '../../assets/coin-dollar-icon-black.svg';
import { ReactComponent as handCoinIconBlack } from '../../assets/hand-coin-icon-black.svg';
import { ReactComponent as investmentArrowIconWhite } from '../../assets/investment-arrow-icon-white.svg';
import { ReactComponent as toolsIconBlack } from '../../assets/tools-icon-black.svg';

import Actions from '../../components/actions/Actions';
import Card from '../../components/card/Card';
import CardInfoColored from '../../components/cardInfoColored/CardInfoColored';
import InfoButton from '../../components/infoButton/InfoButton';
import SpanStatus from '../../components/spanStatus/SpanStatus';
import { useDreContext } from '../../contexts/DreContext';
import { formatToCurrency, formatToDateToDayMonthString, formatToKwh, formatToPercentage } from '../../utils/formatters';
import { navigateToExternal } from '../../utils/utils';
import CardInfo from './cardInfo/CardInfo';
import RecebimentosPopup from './recebimentosDividendosPopup/RecebimentosPopup';

const DreLocacao = () => {
    const { dreData } = useDreContext();

    const previsaoDePagamentoStatus = {
        bgColor: 'var(--verde-positivo)',
        fontColor: 'var(--branco)',
        isFill: true,
        label: 'Pago'
    }

    const printPage = () => {
        navigateToExternal('/print-dre');
    }

    return (
        <main>
            <Actions hasPrintButton={true} onPrint={printPage} />

            <section className={styles.cards}>
                <div>
                    <Card
                        title='Recebimentos'
                        icon={coinDollarIconBlack}
                        classNameContent={`${styles.cardContent} ${styles.cardRecebimentos}`}
                        button={<InfoButton
                            paragraph={<RecebimentosPopup
                                isLocacao={true}
                                data={dreData}
                            />}
                        />}
                    >
                        <div className={styles.values}>
                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Geração"
                                    value={formatToKwh(dreData.geracao)}
                                />
                                <CardInfoColored
                                    color="var(--vermelho-erro)"
                                    label="Consumida"
                                    value={formatToKwh(dreData.consumo)}
                                />
                                <CardInfoColored
                                    color="var(--vermelho-erro)"
                                    label="Energia retida"
                                    value={formatToKwh(dreData.energia_retida)}
                                />
                            </div>

                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Injetada"
                                    value={formatToKwh(dreData.energia_injetada)}
                                />
                                <CardInfoColored
                                    color="var(--verde-positivo)"
                                    label="Saldo meses anteriores"
                                    value={formatToKwh(dreData.saldo_meses_anteriores)}
                                />
                            </div>

                            <div className={styles.group}>
                                <CardInfoColored
                                    color="var(--azul-1)"
                                    label="Compensada"
                                    value={formatToKwh(dreData.energia_compensada)}
                                />
                                <CardInfoColored
                                    color="var(--amarelo)"
                                    label="Saldo"
                                    value={formatToKwh(dreData.saldo)}
                                />
                            </div>
                        </div>

                        <div className={styles.total}>
                            <label>Receita da Usina</label>
                            <span>{formatToCurrency(dreData.receita_usina)}</span>
                        </div>
                    </Card>
                    <Card
                        title='Faturamento'
                        icon={handCoinIconBlack}
                        classNameContent={`${styles.cardContent} ${styles.faturamentoCard}`}
                        button={<InfoButton
                            paragraph={<ul className={styles.popupFaturamento}>
                                <li>A receita da usina é resultado da<br /><strong>soma do valor recebido, recebidos<br />com atraso e juros e multas.</strong></li>

                                <li>O valor pendente é referente a<br />cobranças de beneficiários que não<br />foram liquidadas até a data do<br />pagamento.</li>
                            </ul>}
                        />}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="Valor pendente"
                                value={formatToCurrency(dreData.valor_pendente)}
                            />
                            <CardInfo
                                label="Valor recebido"
                                value={formatToCurrency(dreData.valor_recebido)}
                            />
                            <CardInfo
                                label="Recebidos com atraso"
                                value={formatToCurrency(dreData.recebido_atraso)}
                            />
                            <CardInfo
                                label="Juros e Multas"
                                value={formatToCurrency(dreData.juros_multa)}
                            />
                        </div>

                        <div className={styles.total}>
                            <label>Total</label>
                            <span>{formatToCurrency(dreData.faturamento_bruto)}</span>
                        </div>
                    </Card>
                </div>
                <div>
                    <Card
                        title='Despesas do Consórcio'
                        icon={handCoinIconBlack}
                        classNameContent={styles.cardContent}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="Fatura da Distribuidora"
                                value={formatToCurrency(dreData.fatura_copel)}
                            />
                            <CardInfo
                                label="Gestão e Comerc. (PLIN)"
                                value={formatToCurrency(dreData.gestao_plin)}
                            />
                        </div>

                        <div className={styles.total}>
                            <label>Total</label>
                            <span>{formatToCurrency(dreData.total_copel_gestao_plin)}</span>
                        </div>
                    </Card>
                    <Card
                        title='Receita Tributável da Usina - SPE'
                        icon={coinDollarIconBlack}
                        classNameContent={`${styles.cardContent} ${styles.receitaCard}`}
                    >
                        <div className={styles.values}>
                            <div className={styles.total}>
                                <label>Locação</label>
                                <span>{formatToCurrency(dreData.receita_tributavel_usina)}</span>
                            </div>
                            <div className={styles.previsaoPagamento}>
                                <label>Previsão de pagamento: {formatToDateToDayMonthString(dreData.previsao_pagamento)}</label>
                                <SpanStatus
                                    {...previsaoDePagamentoStatus}
                                />
                            </div>
                        </div>
                    </Card>
                    <hr />
                    <Card
                        title='Projeção de Receita Líquida'
                        hasIcon={false}
                        isPrimary={false}
                        hasParagraph={false}
                        button={<InfoButton
                            paragraph={`Projeção dos dividendos considerando\n o regime tributário do seu CNPJ`}
                        />}
                    />
                </div>
                <div>
                    <Card
                        title='Deduções e Despesas'
                        icon={toolsIconBlack}
                        isPrimary={false}
                        classNameContent={`${styles.cardContent} ${styles.deducoesDespesas}`}
                    >
                        <CardInfo
                            label="PIS/COFINS"
                            value={formatToCurrency(dreData.pis_cofins)}
                            isBold={false}
                        />
                        <CardInfo
                            label="Despesas"
                            value={formatToCurrency(dreData.despesas)}
                            isBold={false}
                            button={<InfoButton
                                positionX='left'
                                paragraph={
                                    <div className={styles.values}>
                                        <CardInfo
                                            label="Despesas com O&M"
                                            value={formatToCurrency(dreData.despesas_oem)}
                                            isBold={false}
                                        />
                                        <CardInfo
                                            label="Arrendamento do Terreno"
                                            value={formatToCurrency(dreData.arrendamento_terreno)}
                                            isBold={false}
                                        />
                                        <CardInfo
                                            label="Seguro da Usina"
                                            value={formatToCurrency(dreData.seguro_usina)}
                                            isBold={false}
                                        />
                                        <CardInfo
                                            label="Despesas Financeiras"
                                            value={formatToCurrency(dreData.despesas_financeiras)}
                                            isBold={false}
                                        />
                                        <CardInfo
                                            label="Outras Despesas"
                                            value={formatToCurrency(dreData.outras_despesas)}
                                            isBold={false}
                                        />
                                    </div>
                                }
                            />}
                        />
                        <div className={styles.total}>
                            <label>EBITDA</label>
                            <span>{formatToCurrency(dreData.ebtida)}</span>
                        </div>
                    </Card>
                    <Card
                        title='Tributos'
                        icon={bankIconBlack}
                        isPrimary={false}
                        classNameContent={styles.cardContent}
                    >
                        <div className={styles.values}>
                            <CardInfo
                                label="CSLL"
                                value={formatToCurrency(dreData.csll)}
                            />
                            <CardInfo
                                label="IRPJ"
                                value={formatToCurrency(dreData.irpj)}
                            />
                            <CardInfo
                                label="IRPJ Adicional"
                                value={formatToCurrency(dreData.irpj_adicional)}
                            />
                        </div>

                        <div className={styles.total}>
                            <label>Total</label>
                            <span>{formatToCurrency(dreData.total_tributos)}</span>
                        </div>
                    </Card>
                    <Card
                        title='Resultado Líquido da Usina'
                        icon={coinDollarIconBlack}
                        isPrimary={false}
                        classNameContent={`${styles.cardContent} ${styles.cardResultado}`}
                    >
                        <div className={styles.total}>
                            <label>Dividendos</label>
                            <span>{formatToCurrency(dreData.total_dividendos)}</span>
                        </div>
                    </Card>
                </div>
            </section>
            <Card
                icon={investmentArrowIconWhite}
                title='Rentabilidade Mensal do Investidor'
                button={<span className={styles.rentabilitySpan}>{formatToPercentage(dreData.rentabilidade)}</span>}
                isPrimary={false}
                classNameCard={styles.cardRentability}
            ></Card>
        </main >
    );
}

export default DreLocacao;