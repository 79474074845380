import React from 'react';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Router from './Routes';
import { AuthProvider } from './contexts/AuthContext';
import { DreProvider } from './contexts/DreContext';
import { FiltrosProvider } from './contexts/FiltrosContext';
import DashboardGatewayFake from './gateways/DashboardGatewayFake';
import DreGatewayFake from './gateways/DreGatewayFake';
import LoginGatewayFake from './gateways/LoginGatewayFake';
import UsinasGatewayFake from './gateways/UsinasGatewayFake';
import { DashboardProvider } from './providers/DashboardProvider';
import { PaginationProvider } from './contexts/PaginationContext';
import { UsinasProvider } from './contexts/UsinasContext';
import { BeneficiariasProvider } from './contexts/BeneficiariasContext';
import BeneficiariasGatewayFake from './gateways/BeneficiariaGatewayFake';

function App() {
    const loginGateway = new LoginGatewayFake();
    const usinasGateway = new UsinasGatewayFake();
    const dreGateway = new DreGatewayFake();
    const dashboardGateway = new DashboardGatewayFake();
    const beneficiariasGateway = new BeneficiariasGatewayFake();

    return (
        <React.StrictMode>
            <ToastContainer autoClose={5000} />
            <AuthProvider loginGateway={loginGateway}>
                <FiltrosProvider>
                    <PaginationProvider>
                        <DreProvider dreGateway={dreGateway}>
                            <UsinasProvider usinasGateway={usinasGateway}>
                                <DashboardProvider dashboardGateway={dashboardGateway}>
                                    <BeneficiariasProvider beneficiariasGateway={beneficiariasGateway}>
                                        <Router />
                                    </BeneficiariasProvider>
                                </DashboardProvider>
                            </UsinasProvider>
                        </DreProvider>
                    </PaginationProvider>
                </FiltrosProvider>
            </AuthProvider>
        </React.StrictMode>
    );
}

export default App;
