import React from "react";
import { useLocation, Link } from "react-router-dom";

import styles from "./DrawerMenu.module.css";
import logoPlinCompactIconBlue from "../../assets/logo-plin-compact-icon-azul.svg";
import logoPlinFullIconBlue from "../../assets/logo-plin-full-icon-blue.svg";
import coinDollarIconBlue from "../../assets/coin-dollar-icon-blue.svg";
import sunIconBlue from "../../assets/sun-icon-blue.svg";
import chartsThreeIconBlue from "../../assets/charts-three-icon-blue.svg";
import papersDoubleLinesIconBlue from "../../assets/papers-double-lines-icon-blue.svg";

interface DrawerMenuParams {
    isOpen: boolean,
    setIsOpen(newValue: boolean): void
}

const DrawerMenu: React.FC<DrawerMenuParams> = ({
    isOpen = false,
    setIsOpen = (newValue) => { }
}) => {
    const location = useLocation();
    const pathname = location.pathname.replace("/", "");
    return (
        <section
            className={`${styles.drawer} ${isOpen ? styles.isOpen : ""}`}
            onMouseOver={() => setIsOpen(true)}
            onMouseOut={() => setIsOpen(false)}
        >
            <div>
                <div className={styles.divLogo}>
                    <img
                        src={
                            isOpen
                                ? logoPlinFullIconBlue
                                : logoPlinCompactIconBlue
                        }
                        alt="Logo da Plin energia"
                        className={styles.logoPlin}
                    />
                </div>
                <nav>
                    <ul>
                        <li className={`${styles.menuItem} ${pathname === "dre" ? styles.selected : ""}`}>
                            <Link to="/dre">
                                <img src={coinDollarIconBlue} alt="DRE" />
                                <span>DRE</span>
                            </Link>
                        </li>
                        <li className={`${styles.menuItem} ${pathname === "usinas" ? styles.selected : ""}`}>
                            <Link to="/usinas">
                                <img src={sunIconBlue} alt="Usinas" />
                                <span>Usinas</span>
                            </Link>
                        </li>
                        <li className={`${styles.menuItem} ${pathname === "dashboard" ? styles.selected : ""}`}>
                            <Link to="/dashboard">
                                <img src={chartsThreeIconBlue} alt="Dashboard" />
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li className={`${styles.menuItem} ${pathname === "documentos" ? styles.selected : ""}`}>
                            <Link to="/documentos">
                                <img src={papersDoubleLinesIconBlue} alt="Documentos" />
                                <span>Documentos</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    );
};

export default DrawerMenu;