import Dre, { Bandeira, StatusDRE } from '../types/Dre';
import { usinaFake } from './UsinasGatewayFake';
import DreGateway from './interfaces/DreGateway';

export const dreFake: Dre = {
	id: '123',
	usina_id: '123',
	usina: usinaFake,
	mes_ref: new Date(),
	bandeira: Bandeira.VERDE,
	status_dre: StatusDRE.PRONTA,
	geracao: 13616,
	consumo: 100,
	energia_retida: 100,
	energia_injetada: 13407,
	saldo: 739,
	saldo_meses_anteriores: 1119,
	energia_compensada: 13787,
	receita_usina: 7169.24,
	valor_pendente: 230.5,
	valor_recebido: 7169.24,
	recebido_atraso: 198.02,
	juros_multa: 5.52,
	faturamento_bruto: 7372.78,
	fatura_copel: -113.53,
	gestao_plin: -952.3,
	total_copel_gestao_plin: -1065.83,
	receita_tributavel_usina: 6306.95,
	pis_cofins: 0,
	despesas_financeiras: 2792.36,
	outras_despesas: 0,
	despesas_oem: 25000,
	arrendamento_terreno: 11625.87,
	seguro_usina: 11581.55,
	despesas: 0,
	ebtida: 6306.95,
	csll: 0,
	irpj: -378.41,
	irpj_adicional: 0,
	total_tributos: -378.41,
	total_dividendos: 5928.54,
	dividendos_proporcao: 0,
	previsao_pagamento: new Date(),
	pago: false,
	rentabilidade: 1.97,
};

export default class DreGatewayFake implements DreGateway {
	async getData(): Promise<Dre> {
		return dreFake;
	}
}
