
import React from 'react';
import SpanStatus from '../../../components/spanStatus/SpanStatus';
import TableComponent from '../../../components/tableComponent/TableComponent';
import { usePaginationContext } from '../../../contexts/PaginationContext';
import { BillStatus, BillStatusType } from '../../../contexts/StatusObjects';
import { useUsinasContext } from '../../../contexts/UsinasContext';
import { formatToCurrency, formatToKwh } from '../../../utils/formatters';
import { useBeneficiariasContext } from '../../../contexts/BeneficiariasContext';

const TableCobrancas = () => {
    const {
        isLoading,
        getCobrancasBeneficiarias,
        cobrancasBeneficiarias
    } = useBeneficiariasContext();

    const { getPagination, setPaginationProp } = usePaginationContext();

    const handleGetData = async () => {
        await getCobrancasBeneficiarias();
    }

    React.useEffect(() => {
        handleGetData();
    }, [isLoading, getPagination().currentPage]);

    const renderHeaders = () => {
        const headerData = ["U.C.", "Consumo (kWh)", "Injetada", "Injeção (R$)", "Saldo (kWh)", "Saldo (R$)", "Status da cobrança"];
        return headerData.map((header, i) => <th key={i}>{header}</th>);
    }

    const renderRows = () => {
        const rowsData = cobrancasBeneficiarias;

        return rowsData.map((row, i) => {
            const billStatus = !!row.data_pagamento;
            const billStatusSpan = BillStatus[billStatus ? 'SIM' : 'NAO'];

            return (
                <tr key={i}>
                    <td>{row.uc}</td>
                    <td>{formatToKwh(row.kwh_consumido)}</td>
                    <td>{formatToKwh(row.energia_injetada_kwh)}</td>
                    <td>{formatToCurrency(row.energia_injetada_reais)}</td>
                    <td>{formatToKwh(row.saldo_kwh)}</td>
                    <td>{formatToCurrency(row.saldo_reais)}</td>
                    <td align='center'>{
                        <SpanStatus
                            bgColor={billStatusSpan.color}
                            label={billStatusSpan.label}
                        />
                    }</td>
                </tr>
            )
        });
    }
    console.log('isLoading > ', isLoading);
    return (
        <TableComponent
            renderHeaders={renderHeaders}
            renderRows={renderRows}
            isLoading={isLoading}
            getPaginationData={getPagination}
            setPaginationData={setPaginationProp}
        />
    );
}

export default TableCobrancas;