import React, { ReactComponentElement } from "react";

import styles from "./Card.module.css";

interface CardParams {
    classNameCard?: string,
    classNameContent?: string,
    icon?: any,
    title?: string,
    paragraph?: string,
    button?: React.ReactNode | false,
    subTitle?: string,
    hasHeader?: boolean,
    hasParagraph?: boolean,
    hasIcon?: boolean,
    isPrimary?: boolean,
    children?: React.ReactNode
}

const Card: React.FC<CardParams> = ({
    classNameCard = "",
    classNameContent = "",
    icon,
    title = "",
    paragraph = "",
    button = false,
    subTitle = "",
    hasHeader = true,
    hasParagraph = true,
    hasIcon = true,
    isPrimary = true,
    children
}) => {
    const Icon = icon;

    return (
        <div className={`${styles.card} ${classNameCard} ${isPrimary ? styles.primary : styles.secondary}`}>
            {hasHeader ? (
                <div className={styles.header}>
                    <div className={styles.left}>
                        <div className={styles.titleContainer}>
                            {hasIcon ? <Icon /> : ""}
                            <h2>{title}</h2>
                        </div>
                        {hasParagraph && paragraph ? <p>{paragraph}</p> : ''}
                    </div>
                    {button && <div className={styles.right}>{button}</div>}
                </div>
            ) : (
                ""
            )}
            {children ? <div className={classNameContent ?? ""}>
                {subTitle.length > 0 ? <p>{subTitle}</p> : ''}
                {children}
            </div> : ''}
        </div>
    );
};

export default Card;
