import { Filtros, FiltrosAction } from "../types/Filtros";

export function FiltrosReducer(
    filtro: Filtros,
    action: FiltrosAction
) {
    switch (action.type) {
        case "updateProp": {
            return {
                ...filtro,
                [action.key]: action.value,
            };
        }
        case "updateAll": {
            return action.filtro;
        }

        default: {
            throw new Error(`Ação inválida: ${action}`);
        }
    }
}

export const initialFiltros: Filtros = {
    usina: "",
    mes_ref: new Date(),
    bandeira: "",
    uc: "",
    distribuidora: "",
    has_reading_error: false,
    bill_status: {
        value: '',
        label: 'Todos'
    },
    nome: "",
    uc_client: "",
    tensao: "",
    modelo_negocio: "",
    potencia: ""
}
