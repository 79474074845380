import Pagination, { PaginationActions } from "../types/Pagination";

export function PaginationReducer(
    pagination: Pagination,
    action: PaginationActions
) {
    switch (action.type) {
        case "updateProp": {
            return {
                ...pagination,
                [action.key]: action.value,
            };
        }

        case "updateAll": {
            return action.pagination;
        }

        default: {
            throw new Error(`Ação inválida: ${action}`);
        }
    }
}

export const initialPagination: Pagination = {
    total: 0,
    lastPage: 0,
    currentPage: 0,
    perPage: 0,
    prev: null,
    next: null
}
