import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ScatterController,
    Title,
    LineController,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    ScatterController,
    Title,
    Tooltip,
    Legend
);

const LineChartComponent = ({
    data = {},
    options = {}
}) => {
    return (
        <Chart
            height={'150%'}
            type='line'
            options={options}
            data={data}
            plugins={[ChartDataLabels]}
        />
    );
}

export default LineChartComponent;