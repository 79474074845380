import React from "react";
import BarChartComponent from "./BarChartComponent";

interface DoubleStackedBarChartParams {
  data: {
    yBottom: Array<number>;
    yMiddle: Array<number>;
    yTop: Array<number>;
    x: Array<number | string>;
  };
  legend: {
    yBottom: string;
    yMiddle: string;
    yTop: string;
  };
  dataLabelFormatter?(label: string): void;
}

const TripleStackedBarChart: React.FC<DoubleStackedBarChartParams> = ({
  data = {
    yBottom: [],
    yMiddle: [],
    yTop: [],
    x: [],
  },
  legend = {
    yBottom: "",
    yMiddle: "",
    yTop: "",
  },
  dataLabelFormatter = (label: string) => {},
}) => {
  const options = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    responsive: true,
    plugins: {
      datalabels: {
        align: "top",
        anchor: "end",
        font: {
          weight: "bold",
          size: 12,
        },
      },
      legend: {
        display: true,
        align: "end",
        position: "top",
      },
      tooltip: {
        yAlign: "bottom",
        callbacks: {
          label: (y: any) => `${y.dataset.label}: ` + dataLabelFormatter(y.raw),
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        border: { display: false },
      },
      y: {
        stacked: true,
        grid: { display: false },
        border: { display: false },
        beginAtZero: true,
        ticks: {
          callback: function (label: string) {
            return dataLabelFormatter(label);
          },
        },
      },
    },
  };

  const chartData = {
    labels: data.x,
    datasets: [
      {
        label: legend.yBottom,
        data: data.yBottom,
        backgroundColor: "#F5E100",
        datalabels: {
          display: false,
          rotation: 90,
        },
      },
      {
        label: legend.yMiddle,
        data: data.yMiddle,
        backgroundColor: "#66CCFF",
        datalabels: {
          display: false,
          rotation: 90,
        },
      },
      {
        label: legend.yTop,
        data: data.yTop,
        backgroundColor: "#0047BA",
        datalabels: {
          color: "#2a2a2a",
          formatter: function (value: string, context: any) {
            const dataIndex = context.dataIndex;
            const datasetIndex = context.datasetIndex;
            const datasets = context.chart.$datalabels._datasets;
            const othersValues: Array<number> = [];

            datasets.map((dt: Array<any>, index: number) => {
              if (index == datasetIndex) return;
              const lineValue =
                dt[index]["$context"]["dataset"]["data"][dataIndex];
              othersValues.push(lineValue);
            });

            const sumOtherValues = othersValues.reduce(
              (acc: number, item: number) => {
                return acc + item;
              },
              0
            );

            const sum = sumOtherValues + value;

            const finalString = dataLabelFormatter(sum);
            return finalString;
          },
        },
      },
    ],
  };

  return <BarChartComponent height="150%" data={chartData} options={options} />;
};

export default TripleStackedBarChart;
