import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
    LogarithmicScale,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LogarithmicScale
);

interface BarChartComponentParams {
    data: ChartData<"bar", (number | [number, number] | null)[], unknown>,
    options: object,
    height?: string,
    width?: string
}

const BarChartComponent: React.FC<BarChartComponentParams> = ({
    data,
    options,
    height = 'auto',
    width = 'auto'
}) => {
    return (
        <Bar
            height={height}
            width={width}
            options={options}
            data={data}
            plugins={[ChartDataLabels]}
        />
    );
}

export default BarChartComponent;